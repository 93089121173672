import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AboutUs = () => {
  return (
    <div className="container my-5 p-4" style={{ background: 'linear-gradient(to right, #003467, #0398f8)', borderRadius: '15px' }}>
      <div className="row mb-5" style={{margin:'20px' ,marginTop:'0px', borderBottom:'1px solid #a8a8a8'}}>
        <div className="col-lg-8 col-md-12 text-white">
          <button className="btn btn-light mb-2" style={{fontWeight:'bold',fontSize:'12px'}}>About Us</button>
          <h1 className="display-4">We solve technology challenges.</h1>
        </div>

        {/* <div className="col-lg-6 col-md-12 text-white">
          <p style={{color:'#fff'}}>
          Your IT Services Partner! We offer customized IT solutions, from managed services to cloud computing and cybersecurity. Empower your business with our expertise for growth and efficiency.
          </p>
        </div> */}
      </div>

      <div className="row" style={{margin:'20px'}}>
        {/* Column 1 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid white' }}>
              <h1 className="card-title">6</h1>
              <p className="card-text" style={{color:'#fff'}}>
                Years Experience
              </p>
            </div>
          </div>
        </div>

        {/* Column 2 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid white' }}>
              <h1 className="card-title">150+</h1>
              <p className="card-text" style={{color:'#fff'}}>
                Happy Customers
              </p>
            </div>
          </div>
        </div>

        {/* Column 3 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid white' }}>
              <h1 className="card-title">300+</h1>
              <p className="card-text" style={{color:'#fff'}}>
                Projects Completed
              </p>
            </div>
          </div>
        </div>

        {/* Column 4 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid white' }}>
              <h1 className="card-title">10
              </h1>
              <p className="card-text" style={{color:'#fff'}}>
                Awards Achievements
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
