import React from "react";

const Intro = ({ title, imageSrc, description }) => {
  return (
    <div className="container my-5">
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <h1 className="heading" style={{ margin: "50px 0px" }}>
          {title}
        </h1>
        <img
          src={imageSrc}
          alt={title}
          style={{ borderRadius: "40px", width: "100%" }}
        />
        <div
          className="col-10"
          style={{
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              textAlign: "center",
              maxWidth: "100%",
              margin: "20px",
              lineHeight: "1.6",
              color:'#000'
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Intro;
