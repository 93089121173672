import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Benefits = ({ benefits }) => {
  return (
    <div style={{background:'#f7f7f7'}}>
      <div className="container py-5" style={{background:'#f7f7f7'}}>
        <div className="row">
          <h1 className="heading" style={{textAlign:'center', margin:'50px 0px'}}>Our Benefits</h1>
          {benefits.map((benefit, index) => (
            <div key={benefit.id} className="col-lg-6 col-md-12 mb-4">
              <div className="service-card text-center p-4 h-100">
                <h3 className="service-title">{benefit.title}</h3>
                <p style={{textAlign:'justify'}}>{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
