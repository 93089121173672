import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const JourneyThroughTime = () => {
  // Data array embedded in the component
  const columnsData = [
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/17048/17048966.png', // Replace with actual logo URL
      title: 'Customer Centricity',
      description: 'Our clients are our priority. We listen to their needs, understand their challenges, and work tirelessly to deliver IT solutions that meet and exceed their expectations. Their success is our success.'
    },
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/9599/9599187.png',
      title: 'Quality Assurance',
      description: 'We have a relentless commitment to quality. We set high standards and rigorously test and monitor our IT services to ensure they meet those standards. Quality is non-negotiable.'
    },
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/10884/10884089.png',
      title: 'Client Focus',
      description: 'Our clients are the center of our IT universe. We listen to their needs, provide tailored solutions, and stand by them every step of the way. Their success is our success, excellence, integrity.'
    },
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/4269/4269808.png',
      title: 'Integrity Always',
      description: 'Integrity is the foundation of trust. We operate with complete transparency, ensuring the highest ethical standards in all our IT operations. Our clients rely on us for honesty and fairness.'
    },
  ];

  return (
    <div style={{backgroundColor:'#f7f7f7'}}>
    <div className="container py-5" style={{backgroundColor:'#f7f7f7'}}>
      <div className="row">
      <h1 className="text-center mb-4 heading" style={{color:'#003467'}}>Our Journey Through Time</h1>
      <p className="text-center mb-5" style={{fontSize:'20px'}}>
      Discover exceptional experiences through testimonials from our satisfied customers.
      </p>
        {columnsData.map((column, index) => (
          <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <div className="d-flex flex-column align-items-start" style={{backgroundColor:'#fff',borderRadius:'30px',padding:'30px',margin:'0px'}}>
              {column.logo && (
                <img 
                  src={column.logo} 
                  alt="Logo" 
                  className="mb-3" 
                  style={{ height: '50px', objectFit: 'contain' }} 
                />
              )}
              <h5 className="mb-2">{column.title}</h5>
              <p>{column.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default JourneyThroughTime;
