import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Cookies from 'js-cookie'
// Sample data for case studies
const caseStudies = [
  {
    id: 1,
    imgSrc: '../assets/code.jpg', // Updated image URL
    title: 'Code Delirium',
    description: 'Creating this project for a software agency specializing in IT services represents a significant milestone for Code Delirium. It underscores our commitment to delivering tailored solutions that meet the unique needs of our clients. This project not only demonstrates our ability to adapt and innovate within the IT services sector but also highlights our dedication to enhancing the operational efficiency and technological capabilities of our partners.',
    keywords: ['Revenue Growth', 'Customer Satisfaction', 'Operational Efficiency'],
  },
  {
    id: 2,
    imgSrc: '../assets/next.jpg', // Updated image URL
    title: 'NexTrade',
    description: 'NexTrade represents a groundbreaking advancement in facilitating global trade through innovative technology. By leveraging cutting-edge solutions, NexTrade aims to streamline and enhance international trade processes, making them more efficient and accessible. This project embodies our vision of breaking down barriers and fostering seamless global commerce, thereby creating new opportunities and driving growth in the global market.',
    keywords: ['Global Trade', 'Technology Integration', 'Operational Efficiency'],
  },
  {
    id: 3,
    imgSrc: '../assets/uniten.jpg', // Updated image URL
    title: 'UNITEN',
    description: 'Uniten, a Malaysian university, is a key project that underscores our role in advancing educational technology. By collaborating with Uniten, we aim to enhance their digital infrastructure and academic systems, supporting their goal of delivering exceptional education and research opportunities. This project highlights our dedication to fostering innovation in the education sector and creating solutions that empower students and faculty alike.',
    keywords: ['Educational Technology', 'Digital Infrastructure', 'Academic Systems'],
  },
  {
    id: 4,
    imgSrc: '../assets/reso.jpg', // Updated image URL
    title: 'ResoSyncer',
    description: 'ResoSyncer, our innovative Resource Manager, is designed to transform how organizations manage and optimize their resources. This project reflects our commitment to delivering powerful solutions that streamline resource allocation, enhance operational efficiency, and provide valuable insights for better decision-making. ResoSyncer embodies our dedication to creating tools that drive productivity and support dynamic resource management strategies.',
    keywords: ['Resource Management', 'Operational Efficiency', 'Productivity'],
  },
  {
    id: 5,
    imgSrc: '../assets/neuro.jpg', // Updated image URL
    title: 'Neuronetix',
    description: 'Neronetix, a Swiss IT company, signifies a major achievement in our portfolio, showcasing our ability to deliver cutting-edge technology solutions in the highly competitive IT sector. This project emphasizes our expertise in crafting advanced, reliable, and scalable solutions that meet the rigorous demands of international clients. By partnering with Neronetix, we demonstrate our capability to support sophisticated IT operations and contribute to the growth and success of leading technology firms.',
    keywords: ['Technology Solutions', 'Scalability', 'IT Operations', 'IT Solutions'],
  },
  {
    id: 6,
    imgSrc: '../assets/gringo.jpg', // Updated image URL
    title: 'Gringo',
    description: 'GringoTeachesU is a dynamic platform dedicated to helping students master English through engaging and interactive learning experiences. This project highlights our commitment to enhancing educational opportunities by providing a user-friendly, accessible tool that supports language acquisition. By developing GringoTeachesU, we aim to empower students with the skills they need to succeed in English-speaking environments and advance their personal and professional goals.',
    keywords: ['Language Learning', 'Interactive Platform', 'Educational Opportunities'],
  },
  {
    id: 7,
    imgSrc: '../assets/swissmine.jpg', // Updated image URL
    title: 'Swissmine',
    description: 'Swissmine is a cutting-edge platform designed to simplify the complexities of cryptocurrency mining. With Swissmine, users can effortlessly leverage the benefits of a professional mining host, making the process accessible and efficient. Our focus is on delivering a streamlined experience that allows users to start hosting their mining operations with ease, while enjoying the advantages of advanced technology and expert support.',
    keywords: ['Cryptocurrency Mining', 'Professional Hosting', 'Efficiency'],
  },
  {
    id: 8,
    imgSrc: '../assets/scholor.jpg', // Updated image URL
    title: 'Scholar Suites',
    description: 'Scholarsuites is an innovative platform designed to simplify the process of finding and securing hostel accommodations for students. This project reflects our commitment to enhancing the student experience by providing a streamlined, user-friendly solution for locating and booking suitable housing. Scholarsuites aims to make the search for comfortable, affordable, and convenient living options as effortless as possible, supporting students in their academic journey.',
    keywords: ['Student Housing', 'Accommodation Booking', 'User-Friendly Platform'],
  },
  {
    id: 9,
    imgSrc: '../assets/notes.jpg', // Updated image URL
    title: 'NeaterNotes',
    description: 'Neaternotes is an advanced platform that leverages AI technology to revolutionize how notes are scanned, managed, and organized. Designed to streamline the process of handling handwritten and digital notes, Neaternotes offers an intelligent solution that enhances productivity and organization. This project embodies our commitment to integrating cutting-edge technology to improve everyday tasks and support efficient information management.',
    keywords: ['AI Technology', 'Note Management', 'Productivity'],
  },
];


const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // Rotate every 3 seconds
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
};

const truncateDescription = (text, maxLength = 100) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};

const CaseStudies = () => {
  return (
    <div style={{background:'#f7f7f7'}}>
    <div className="container py-5" style={{overflow:'hidden',background:'#f7f7f7'}}>
    <h1 className="heading" style={{textAlign:'center',marginTop:'50px'}}>Case Studies</h1>
    <p style={{textAlign:'center'}}>Case studies that showcase the agency's approach, process, and results for specific clients.</p>
      <Slider {...sliderSettings}>
        {caseStudies.map((study) => (
          <div key={study.id} className="case-study-card-wrapper" onClick={() => {
            Cookies.set("cardid", study.id, { expires: 1 });
            window.location.href = "/portfolio-detail";
          }}>
            <div className="case-study-card">
              <img
                src={study.imgSrc}
                alt={study.title}
                className="case-study-img"
              />
              <div className="case-study-content">
              <div className="case-study-keywords">
                  {study.keywords.map((keyword, i) => (
                    <span key={i} className="case-study-keyword badge" style={{color:'#0398f8'}}>
                      {keyword}
                    </span>
                  ))}
                </div>
                <h5 className="case-study-title">{study.title}</h5>
                <p className="case-study-description">
        {truncateDescription(study.description)}
      </p>
                
              </div>
            </div>
            <hr className="case-study-divider" />
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default CaseStudies;
