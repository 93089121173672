import React, { useEffect, useRef } from 'react';

const WorkingProcess = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const processItems = containerRef.current.querySelectorAll('.process-item');

    const observerOptions = {
      root: null, // relative to the viewport
      rootMargin: '0px',
      threshold: 0.1, // trigger when 10% of the component is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          processItems.forEach((item, index) => {
            setTimeout(() => {
              item.style.animation = `moveUp 1s ease forwards`;
              item.style.animationDelay = `${index * 0.5}s`;
            }, index * 500); // Adjust the delay as needed
          });
          observer.disconnect(); // Stop observing after animation starts
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect(); // Clean up observer on component unmount
    };
  }, []);

  return (
    <div ref={containerRef} className="section bg-working-process">
      <div className="base-container w-container">
        <div className="working-process-wrap">
          <div className="section-title-wrapp-working-process">
            <h2 className="careers-heading">Our Working Process</h2>
            <p className="paragraph-large white-text">
              Our process is a systematic journey comprising four stages:
              Discovery, Proposal, Implementation, and Optimization.
            </p>
          </div>
          <div className="w-layout-grid flex-stages">
            <div className="process-item right-margin gap-80">
              <div className="stage">
                <div className="process-item-wrap">
                  <h2><span className="text-white">01</span></h2>
                  <div className="process-divider"></div>
                </div>
                <h5 className="text-white"><strong>Discovery Call</strong></h5>
                <p className="stage-description">
                  We begin by thoroughly understanding your objectives.
                </p>
              </div>
            </div>
            <div className="process-item gap-80">
              <div className="stage">
                <div className="process-item-wrap">
                  <h2><span className="text-white">02</span></h2>
                </div>
                <h5 className="text-white text-center">
                  <strong>Proposal</strong>
                </h5>
                <p className="stage-description">
                  We propose the infrastructure, systems, and processes to
                  achieve those goals.
                </p>
              </div>
            </div>
            <div className="process-item right-margin gap-80">
              <div className="stage">
                <h2><span className="text-white">03</span></h2>
                <h5 className="text-white text-center">
                  <strong>Implementation and Execution</strong>
                </h5>
                <p className="stage-description">
                  We execute the project plan, which can involve building,
                  testing, deploying, and supporting the software.
                </p>
              </div>
            </div>
            <div className="process-item gap-80">
              <div className="stage">
                <h2><span className="text-white">04</span></h2>
                <h5 className="text-white text-center">
                  <strong>Evaluation and Maintenance</strong>
                </h5>
                <p className="stage-description">
                  We assess the results, incorporate feedback and continually
                  improve.
                </p>
              </div>
            </div>
            <div className="gradient-services">
              <div className="home-3-company-wrap"></div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a27110_logo%20home.svg"
        loading="lazy"
        alt="bg logo"
        className="logo-bg-home-process right"
      />
      <img
        src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a2710f_Logo%20Bg.svg"
        loading="lazy"
        alt="bg logo"
        className="logo-bg-home-process"
      />
      <style jsx>{`
        .careers-heading {
          font-size: 4rem; /* Default size for large screens */
          font-weight:bold;
          text-align : left;
          color:#fff;
        }

        @media (max-width: 1200px) {
          .careers-heading {
            font-size: 3rem; /* Slightly smaller for medium screens */
          }
        }

        @media (max-width: 768px) {
          .careers-heading {
            font-size: 2.5rem; /* Smaller for tablets */
          }
        }

        @media (max-width: 576px) {
          .careers-heading {
            font-size: 2rem; /* Smallest for mobile phones */
          }
        }
      `}</style>
    </div>
  );
};

export default WorkingProcess;
