import React from "react";

const Solutions = ({ title, description, features, imageUrl }) => (
  <div className="web-dev-intro container my-5">
    <div className="row align-items-center">
      {/* Column 1: Image */}
      <div className="col-md-6 d-flex justify-content-center">
        <img
          src={imageUrl}
          alt="Solutions Image"
          className="web-dev-image"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            borderRadius: "8px",
          }}
        />
      </div>
      {/* Column 2: Text and Features */}
      <div className="col-md-6 d-flex flex-column justify-content-center">
        <h2 className="heading" style={{ fontWeight: "500" }}>
          {title}
        </h2>
        <div style={{ borderBottom: '1px solid #e4e4e4', paddingBottom: '10px' }}>
          {description}
        </div>
        <div style={{ marginTop: '20px' }}>
          {features.map((feature, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', marginTop: '10px' }}>
              <div>
                <h4 style={{ margin: '0', color: '#000' }}>{feature.title}</h4>
                <p style={{ margin: '0', color: '#000', fontWeight: 'normal' }}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <style>
      {`
        .point-item {
          font-size: 1.4rem;
          margin-top: 15px;
          font-weight: normal;
        }

        @media (max-width: 768px) {
          .heading {
            font-size: 1.5rem;
          }

          .point-item {
            font-size: 1rem;
          }
        }
      `}
    </style>
  </div>
);

export default Solutions;
