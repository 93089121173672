import React from 'react';
import {Link} from 'react-router-dom';

const PricingSection = () => {
  const buttonHoverStyle = {
    backgroundColor: '#003467', // Hover background color
    border: '4px solid #0398f8' 
  };
  const tbuttonStyle = {
    backgroundColor: '#00000000', // Default button background color
    color: 'white', // Text color
    transition: 'background-color 0.3s ease', // Smooth transition
  };
  
  return (
    <div style={{background: 'linear-gradient(to top, #8fd1fc, #fff)', padding: '50px 0', overflowX: 'hidden'}}>
      <div className="w-layout-blockcontainer base-container w-container">
        <div className="pricing-text-wrapper">
          <div className="pricing-2-grid">
            <div className="w-layout-grid grid-2">
              
              <div className="pricing-left-wrapper">
                <div className="text-block-13" style={{color: '#0398f8'}}>Pricing</div>
                <h1 className="heading-13" style={{fontWeight:'bold'}}>
                  We utilize
                  <strong className="bold-text" style={{color: '#0398f8'}}>{' '}state-of-the-art AI advancements</strong>
                  {" "}to create exceptional technology solutions, 
                  <strong className="bold-text" style={{color: '#0398f8'}}>{' '}ensuring timely delivery and cost efficiency</strong>.
                </h1>
                <h4 className="">
                Since 2018, we have dedicated ourselves to empowering clients by offering premium custom services designed to foster growth, innovation, and transformation in their businesses.
                </h4>
                {/* <a
                  href="./reviews/index.html"
                  className="button-secondary-white pricing w-inline-block"
                  onMouseOver={e => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor} 
                  onMouseOut={e => e.currentTarget.style.backgroundColor = tbuttonStyle.backgroundColor}
                >
                  <div className="text-block-18">See Our Reviews</div>
                </a> */}
              </div>

              <div className="pricing-right-wrapper">
                <div className="w-layout-layout quick-stack-13 wf-layout-layout">
                  <div className="w-layout-cell">
                    <div className="text-block-13" style={{color: '#0398f8'}}>Starting from</div>
                    <h3>$25 / hr</h3>
                  </div>
                  <div className="w-layout-cell">
                    <Link to="/contact" className="button-primary pricing w-inline-block">
                      <div className="text-block-19">Contact Us →</div>
                    </Link>
                  </div>
                  <div className="w-layout-cell cell-16">
                    <p className="main-website-paragraph">
                    With our expertise in AI tools such as ChatGPT and CoPilot, we enhance software development, delivering superior results and maximizing your project’s ROI. Experience a risk-free two-week trial with us—if you’re not satisfied with the value we bring, we’ll keep working for free until you are.
                    </p>
                  </div>
                </div>

                <div className="w-layout-layout quick-stack-14 wf-layout-layout">
                  <div className="w-layout-cell">
                      <div className="w-layout-hflex pricing-features-flex">
                        <img
                          src="../assets/1.svg"
                          loading="lazy"
                          width="20"
                          alt="Software Outsourcing"
                          className="pricing-feature-image"
                        />
                        <p className="main-website-paragraph" style={{textDecoration:'none'}}>
                          <strong className="bold-text-small" style={{textDecoration:'none'}}>Custom Software Development<br /></strong>
                          We handle the entire software development lifecycle, from concept to delivery, providing comprehensive solutions tailored to your specific needs.
                        </p>
                      </div>
                  </div>

                  <div className="w-layout-cell">
                      <div className="w-layout-hflex pricing-features-flex">
                        <img
                          src="../assets/2.svg"
                          loading="lazy"
                          width="450"
                          alt="Staff Augmentation"
                          className="pricing-feature-image"
                        />
                        <p className="main-website-paragraph">
                          <strong className="bold-text-small">Talent Augmentation<br /></strong>
                          Enhance your team's capabilities with our skilled professionals, streamlining recruitment and reducing training time for immediate productivity.
                        </p>
                      </div>
                  </div>

                  <div className="w-layout-cell">
                      <div className="w-layout-hflex pricing-features-flex">
                        <img
                          src="../assets/3.svg"
                          loading="lazy"
                          width="692"
                          alt="Dedicated Teams"
                          className="pricing-feature-image"
                        />
                        <p className="main-website-paragraph">
                          <strong className="bold-text-small">Managed Development Teams<br /></strong>
                          Speed up your project timelines with a dedicated team of experts, fully integrated into your operations to drive results and meet your development goals.
                        </p>
                      </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
