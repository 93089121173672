import React from 'react'
import CareerBanner from '../Components/Career/CareerBanner'
import CareerPerks from '../Components/Career/CareerPerks'
import OnboardingProcess from '../Components/Career/OnboardingProcess'
import HomeCtaSection from '../Components/Career/HomeCtaSection'

export default function Career() {
  return (
    <div>
        <CareerBanner/>
        <CareerPerks/>
        <OnboardingProcess/>
        <HomeCtaSection/>
    </div>
  )
}
