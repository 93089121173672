import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";

const services = [
  {
    id: "Web Development",
    href: "./services/web-development/index.html",
    imgSrc: "../assets/s1.png",
    title: "Web Development",
    description:
      "Deliver high-quality web development, design, and functionality tailored for your business, ensuring a seamless user experience and innovative solutions.",
  },
  {
    id: "Mobile App Development",
    href: "./services/digital-transformation/index.html",
    imgSrc: "../assets/s2.png",
    title: "Mobile App Development",
    description:
      "Create seamless and engaging mobile applications designed to meet your specific business needs and enhance user experience.",
  },
  {
    id: "Digital Marketing",
    href: "./services/generative-ai/index.html",
    imgSrc: "../assets/s3.png",
    title: "Digital Marketing",
    description:
      "Boost your online presence with strategic digital marketing solutions that drive traffic, engagement, and conversions.",
  },
  {
    id: "Generative AI",
    href: "./services/mobile-app-development/index.html",
    imgSrc: "../assets/s4.png",
    title: "Generative AI",
    description:
      "Harness the power of generative AI to create innovative and intelligent solutions that enhance your business processes and customer interactions.",
  },
  {
    id: "Custom Development",
    href: "./services/custom-development/index.html",
    imgSrc: "../assets/s5.png",
    title: "Custom Development",
    description:
      "Provide bespoke software development solutions tailored to your unique business requirements, ensuring optimal performance and scalability.",
  },
  {
    id: "Product Development",
    href: "./services/cybersecurity-solutions/index.html",
    imgSrc: "../assets/s6.png",
    title: "Product Development",
    description:
      "Drive innovation and bring your ideas to life with comprehensive product development services, from initial concept to final market-ready product.",
  },
  {
    id: "Graphic Design",
    href: "./services/microsoft-dynamics-365-business-solutions/index.html",
    imgSrc: "../assets/s7.png",
    title: "Graphic Design",
    description:
      "Craft visually stunning and impactful graphic designs that elevate your brand identity and captivate your audience.",
  },
  {
    id: "DevOps",
    href: "./services/devops/index.html",
    imgSrc: "../assets/devops.png", // Replace with actual image URL for DevOps
    title: "DevOps",
    description:
      "Optimize your software development lifecycle with DevOps practices that enhance collaboration, automation, and continuous delivery for faster and more reliable deployments.",
  },
  {
    id: "UI/UX",
    href: "./services",
    imgSrc: "../assets/design.png", // Replace with actual image URL for UI/UX Design
    title: "UI/UX Design",
    description:
      "Enhance user engagement and satisfaction with intuitive and aesthetically pleasing UI/UX design tailored to your target audience and business objectives.",
  },
];

const Services = () => {
  return (
    <div style={{ background: "#f7f7f7" }}>
      <div className="container py-5" style={{ background: "#f7f7f7" }}>
        <div className="row">
          {/* <button className="btn mb-2" style={{fontWeight:'bold',fontSize:'12px'}}>HOW WE DO</button> */}
          <h1
            className="heading"
            style={{ textAlign: "center", margin: "50px 0px" }}
          >
            Our Services
          </h1>
          {services.map((service, index) => (
            <div key={service.id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
              <div
                className="service-card text-center p-4 h-100"
                onClick={() => {
                  Cookies.set("service", service.id, { expires: 1 });
                  window.location.href = "/services";
                }}
                style={{cursor:'pointer'}}
              >
                <img
                  src={service.imgSrc}
                  alt={service.title}
                  className="mb-3 mx-auto"
                  style={{ height: "50px" }}
                />
                <h3 className="service-title">{service.title}</h3>
                <p style={{ textAlign: "center" }}>{service.description}</p>
                {/* <a href={service.href} className="stretched-link"></a> */}
                <div className="hover-line"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
