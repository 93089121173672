import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const LogoSection = () => {
  return (
    <section className="container"  style={{overflowX:'hidden',marginTop:'50px',marginBottom:'50px'}}>
        <h2 style={{margin:'20px',textAlign:'center'}}>Trusted by specialists all around the world</h2>
      <div className="w-layout-blockcontainer base-container no-padding w-container">
        <div className="logo-scroll-wrapper">
          <div className="client-logo-wrapper">
            <img
              src="../assets/1.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
           <img
              src="../assets/2.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/3.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/4.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/5.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/6.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/7.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/8.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/9.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
            <img
              src="../assets/10.jpg"
              loading="lazy"
              alt="Dealty Logo"
              className="company-logo"
            />
          </div>
        
        
        </div>
      </div>
    </section>
  );
};

export default LogoSection;
