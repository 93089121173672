import React, { useState } from "react";

const FullScreenImageViewer = ({ images, currentIndex, onClose }) => {
  const [index, setIndex] = useState(currentIndex);

  const handlePrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        onClick={onClose}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          background: "transparent",
          border: "none",
          color: "white",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        &times;
      </button>
      <button
        onClick={handlePrevious}
        style={{
          position: "absolute",
          left: "20px",
          background: "transparent",
          border: "none",
          color: "white",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        &lt;
      </button>
      <img
        src={images[index]}
        alt={`Slide ${index}`}
        style={{ maxHeight: "90%", maxWidth: "90%", objectFit: "contain" }}
      />
      <button
        onClick={handleNext}
        style={{
          position: "absolute",
          right: "20px",
          background: "transparent",
          border: "none",
          color: "white",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        &gt;
      </button>
    </div>
  );
};

export default FullScreenImageViewer;
