import React from 'react'
import CommunityEngagement from '../Components/Gallery/CommunityEngagement'
import KeyNoteSpeaking from '../Components/Gallery/KeyNoteSpeaking'
import SponsorShips from '../Components/Gallery/SponsorShips'
import Blogs from '../Components/Gallery/Blogs'

export default function Gallery() {
  return (
    <div>
        <CommunityEngagement/>
        <SponsorShips/>
        <KeyNoteSpeaking/>
        <Blogs/>
    </div>
  )
}
