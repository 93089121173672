import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [activeSubMenu1, setActiveSubMenu1] = useState(false);
  const [activeSubMenu2, setActiveSubMenu2] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubMenuHover = (index) => {
    setActiveSubMenu(index);
  };

  const clearSubMenuHover = () => {
    setActiveSubMenu(null);
  };

  const closeMenuOnClickOutside = (e) => {
    if (
      !e.target.closest(".submenu") &&
      !e.target.closest(".nav-link-header")
    ) {
      setIsMenuOpen(false);
      setActiveSubMenu1(false);
      setActiveSubMenu2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenuOnClickOutside);
    return () => {
      document.removeEventListener("click", closeMenuOnClickOutside);
    };
  }, []);

  const linkStyle = {
    color: "black",
    textDecoration: "none",
    transition: "color 0.3s ease",
    padding: "10px 15px",
    position: "relative",
    cursor: "pointer",
  };

  const submenuStyle = {
    position: "absolute",
    backgroundColor: "white",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.5)",
    padding: "10px",
    zIndex: 1000,
    width: "320px",
    display: "block",
    borderRadius: "5px",
    marginTop: "10px",
  };

  const lineStyle = {
    position: "absolute",
    left: "0px",
    top: "50%",
    width: "20px",
    height: "2px",
    backgroundColor: "#0398f8",
    transform: "scaleX(0)",
    transition: "transform 0.3s ease",
  };

  const submenuItems = [
    "Web Development",
    "Mobile App Development",
    "Generative AI",
    "Custom Development",
    "Digital Marketing",
    "Product Development",
    "Graphic Design",
    "DevOps",
    "UI/UX",
  ];

  const submenuItems2 = ["Fleet Vision X", "ResoSyncer", "SmartBD"];

  return (
    <div
      data-animation="over-left"
      className={`navbar-absolute-trans w-nav ${isMenuOpen ? "w--open" : ""}`}
      data-easing2="ease"
      data-easing="ease"
      data-collapse="medium"
      role="banner"
      data-no-scroll="1"
      data-duration="400"
      data-doc-height="1"
      style={{
        minHeight: "90px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000, // Optional, to ensure the navbar stays on top of other elements
      }}
    >
      <div className="nav-container w-container" style={{ marginTop: "15px" }}>
        <div className="nav-menu-wrapper">
          <a
            href="/"
            aria-current="page"
            className="brand w-nav-brand w--current"
          >
            <img
              src="../assets/logo.jpg"
              loading="lazy"
              alt="Tiers Logo"
              className="logo-header-image"
              style={{ width: "150px", height: "auto" }}
            />
          </a>
          <nav
            role="navigation"
            className={`nav-menu w-nav-menu ${
              isMenuOpen ? "w--nav-menu-open" : ""
            }`}
          >
            <div className="tablet-menu">
              <a
                href="/"
                aria-current="page"
                className="brand-tablet w-nav-brand w--current"
              >
                <img
                  src="../assets/logo.jpg"
                  loading="lazy"
                  alt="Tiers Logo"
                  className="logo-header-image"
                  style={{ width: "150px", height: "auto" }}
                />
              </a>
              <div
                className="close-menu-button w-nav-button"
                onClick={toggleMenu}
              >
                <img
                  src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a26f14_close-btn.svg"
                  loading="lazy"
                  alt="Close Menu Icon"
                  className="nav-close-icon"
                />
              </div>
            </div>
            <div className="menu-wrap">
              <Link
                to="/about"
                className="nav-link-header w-inline-block"
                style={linkStyle}
                onClick={()=>{if(window.innerWidth <= 768){window.location.href = '/about'}}}
              >
                <div className="link-text-wrap" style={{ marginRight: "15px" }}>
                  <div className="">About</div>
                </div>
              </Link>
              <div
                className="nav-link-header w-inline-block"
                style={linkStyle}
                onMouseEnter={() => setActiveSubMenu1(true)}
                onMouseLeave={() => setActiveSubMenu1(false)}
              >
                <div
                  className="link-text-wrap"
                  style={{ marginRight: "15px", fontWeight: "500" }}
                >
                  <div
                    className=""
                    onClick={() => setActiveSubMenu1(!activeSubMenu1)}
                  >
                    Services
                  </div>
                </div>
                {activeSubMenu1 && (
                  <div style={submenuStyle} className="submenu">
                    {submenuItems.map((item, index) => (
                      <Link
                        to={`/services`}
                        style={{
                          display: "block",
                          padding: "10px 15px",
                          color: activeSubMenu === index ? "#0398f8" : "black",
                          textDecoration: "none",
                          position: "relative",
                          transition: "color 0.3s ease",
                          textAlign: "left",
                          paddingLeft: "25px", // Space between text and line
                        }}
                        key={index}
                        onMouseEnter={() => handleSubMenuHover(index)}
                        onMouseLeave={clearSubMenuHover}
                        onClick={() => {
                          Cookies.set("service", item, { expires: 1 });
                          if(window.innerWidth <= 768){window.location.href = '/services'}
                        }}
                      >
                        <div
                          style={{
                            ...lineStyle,
                            transform:
                              activeSubMenu === index
                                ? "scaleX(1)"
                                : "scaleX(0)",
                          }}
                        />
                        {item}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <div
                className="nav-link-header w-inline-block"
                style={linkStyle}
                onMouseEnter={() => setActiveSubMenu2(true)}
                onMouseLeave={() => setActiveSubMenu2(false)}
              >
                <div
                  className="link-text-wrap"
                  style={{ marginRight: "15px", fontWeight: "500" }}
                >
                  <div
                    className=""
                    onClick={() => setActiveSubMenu2(!activeSubMenu2)}
                  >
                    Products
                  </div>
                </div>
                {activeSubMenu2 && (
                  <div style={submenuStyle} className="submenu">
                    {submenuItems2.map((item, index) => (
                      <Link
                        to={`/products`}
                        style={{
                          display: "block",
                          padding: "10px 15px",
                          color: activeSubMenu === index ? "#0398f8" : "black",
                          textDecoration: "none",
                          position: "relative",
                          transition: "color 0.3s ease",
                          textAlign: "left",
                          paddingLeft: "25px", // Space between text and line
                        }}
                        key={index}
                        onMouseEnter={() => handleSubMenuHover(index)}
                        onMouseLeave={clearSubMenuHover}
                        onClick={() => {
                          Cookies.set("product", item, { expires: 1 });
                          if(window.innerWidth <= 768){window.location.href = '/products'}
                        }}
                      >
                        <div
                          style={{
                            ...lineStyle,
                            transform:
                              activeSubMenu === index
                                ? "scaleX(1)"
                                : "scaleX(0)",
                          }}
                        />
                        {item}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              <Link
                to="/portfolio"
                className="nav-link-header w-inline-block"
                style={linkStyle}
                onClick={()=>{if(window.innerWidth <= 768){window.location.href = '/portfolio'}}}
              >
                <div className="link-text-wrap" style={{ marginRight: "15px" }}>
                  <div className="">Portfolio</div>
                </div>
              </Link>
              <Link
                to="/career"
                className="nav-link-header w-inline-block"
                style={linkStyle}
                onClick={()=>{if(window.innerWidth <= 768){window.location.href = '/career'}}}
              >
                <div className="link-text-wrap" style={{ marginRight: "15px" }}>
                  <div className="">Careers</div>
                </div>
              </Link>
              <Link
                to="/gallery"
                className="nav-link-header w-inline-block"
                style={linkStyle}
                onClick={()=>{if(window.innerWidth <= 768){window.location.href = '/gallery'}}}
              >
                <div className="link-text-wrap">
                  <div className="">Gallery</div>
                </div>
              </Link>
            </div>
            <div className="w-embed">
              <style>
                {`.nav-link-header.w--current .white-link {
                  color: var(--primary) !important;
                }
                .submenu a:hover {
                  color: #0398f8;
                }
                .nav-link-header:hover .link-text-wrap {
                  color: #0398f8;
                }
                `}
              </style>
            </div>
          </nav>
          <div className="header-buttons-wrapper"></div>
          <div className="header-buttons-wrapper"></div>
          <div className="menu-button w-nav-button" onClick={toggleMenu}>
            <img
              src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a26f13_menu-btn.svg"
              loading="lazy"
              alt="Close Menu Icon"
              className="nav-close-icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
