import React from "react";
import HomeBanner from '../Components/Home/HomeBanner'
import AboutUs from "../Components/Home/AboutUs";
import FourPoints from "../Components/Home/FourPoints";
import Services from "../Components/Home/Services";
import Industries from "../Components/Home/Industries";
import CaseStudies from "../Components/Home/CaseStudies";
import LogoSection from "../Components/Home/LogoSection";
import ReviewSection from "../Components/Home/ReviewSection";
import ContactUs from "../Components/Home/ContactUs";

const Home = () => {
  return (<div className="body" style={{overflowX:'hidden'}}>
    <HomeBanner/>
    <AboutUs/>
    <FourPoints/>
    <Services/>
    <Industries/>
    <CaseStudies/>
    <LogoSection/>
    <ReviewSection/>
    <ContactUs/>
  </div>);
};

export default Home;
