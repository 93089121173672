import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Client = ({
  imageSrc,
  title,
  description,
  clientName,
  country,
  date,
}) => {
  return (
    <div className="container my-5">
      <div className="row align-items-center">
        {/* Image Column */}
        <div className="col-md-6">
          <img
            src={imageSrc}
            alt={title}
            className="img-fluid"
            style={{ borderRadius: "20px" }}
          />
        </div>

        {/* Details Column */}
        <div className="col-md-6">
          <div style={{ padding: "0px 5%" }}>
            <h1
              className="heading"
              style={{ margin: "0px 0px", fontWeight: "500" }}
            >
              {title}
            </h1>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                textAlign: "left",
                maxWidth: "100%",
                margin: "15px 0px",
                lineHeight: "1.6",
              }}
            >
              {description}
            </p>

            {/* Client Details */}
            <p style={{ fontSize: "20px", fontWeight: "normal" }}>
              <strong style={{ color: "#000" }}>Client:</strong> {clientName}
            </p>
            <p style={{ fontSize: "20px", fontWeight: "normal" }}>
              <strong style={{ color: "#000" }}>Country:</strong> {country}
            </p>
            <p style={{ fontSize: "20px", fontWeight: "normal" }}>
              <strong style={{ color: "#000" }}>Date:</strong> {date}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;
