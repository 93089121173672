import React from "react";

const perks = [
  {
    title: "Career Advancement Paths",
    description:
      "Clear career development plans to guide and support your professional growth.",
  },
  {
    title: "Collaborative Work Environment",
    description:
      "A supportive, open workspace that encourages teamwork and innovation.",
  },
  {
    title: "Remote Work Options",
    description:
      "Flexibility to work from anywhere, supported by our robust remote work policies.",
  },
  {
    title: "Recognition Programs",
    description:
      "Regular awards and recognition for exceptional performance and achievements.",
  },
  {
    title: "Inclusive Diversity Initiatives",
    description:
      "Embracing diversity and inclusion, valuing every individual’s unique contributions.",
  },
  {
    title: "Cutting-Edge Technology Access",
    description:
      "Utilizing the latest tools and technologies to enhance productivity and maintain a competitive edge.",
  },
];

const CareerPerks = () => {
  return (
    <div className="section without-bottom-spacing">
      <div className="base-container w-container">
        <div className="values-wrapper">
          <div className="values-header-wrap">
            <h2 className="text-center big-heading-blue" style={{fontWeight:'bold',color:'#003467'}}>
              It’s Time to Amplify Your Career
            </h2>
          </div>
          <div className="advantages-wrapper">
            {perks.map((perk, index) => (
              <div key={index} className="advantage dark" style={{background:'#003467',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                <h3 className="white-text">{perk.title}</h3>
                <p className="white-text">{perk.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPerks;
