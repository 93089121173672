import React, { useState } from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

// Sample caseStudies array
const caseStudies = [
  {
    id: 1,
    imgSrc: [
      "../assets/Blogs and NewsLetter/Tiers Limited Annual Dinner/1.jpg",
      "../assets/Blogs and NewsLetter/Tiers Limited Annual Dinner/2.jpg",
      "../assets/Blogs and NewsLetter/Tiers Limited Annual Dinner/3.jpg",
      "../assets/Blogs and NewsLetter/Tiers Limited Annual Dinner/4.jpg",
      "../assets/Blogs and NewsLetter/Tiers Limited Annual Dinner/5.jpg",
    ],
    title: "TIERS Limited Annual Dinner 2023",
    keywords: [
      "Corporate Event",
      "Networking",
      "Business Dinner",
      "Annual Celebration",
    ],
    description:
      "The TIERS Limited Annual Dinner 2023 brought together top professionals for an evening of networking and celebration. The event featured keynotes, a gourmet dinner, and discussions on industry trends.",
  },
  {
    id: 2,
    imgSrc: [
      "../assets/Blogs and NewsLetter/Mango Fest 2024/4.jpg",
      "../assets/Blogs and NewsLetter/Mango Fest 2024/2.jpg",
      "../assets/Blogs and NewsLetter/Mango Fest 2024/1.jpg",
    ],
    title: "Mango Fest 2024",
    keywords: [
      "Food Festival",
      "Community Event",
      "Cultural Celebration",
      "Local Attractions",
    ],
    description:
      "Mango Fest 2024 was a vibrant celebration of local culture and cuisine, featuring various mango-themed dishes, live entertainment, and community activities. A delightful event for all ages.",
  },
  // ... more items
];

const Blogs = () => {
  // Use useState to manage the currently selected post
  const [selectedPost, setSelectedPost] = useState(caseStudies[0]); // Default to the first post

  return (
    <div style={{ background: "#f7f7f7" }}>
      <h1 className="heading" style={{ textAlign: "center" }}>
        Blogs
      </h1>
      <div
        className="post-with-sidebar container py-5"
        style={{ background: "#f7f7f7" }}
      >
        <div className="blog-collection-one-post w-dyn-list">
          <div
            role="list"
            className="blog-collection-one-post-list w-dyn-items"
            style={{ background: "#f7f7f7" }}
          >
            {/* Display the selected post */}
            <div
              role="listitem"
              className="blog-collection-one-post-item w-dyn-item"
            >
              <article className="article" style={{ background: "#f7f7f7" }}>
                <p className="blog-sidebar-post-category">
                  {selectedPost.keywords[0]}{" "}
                  {/* Display the first keyword as category */}
                </p>

                {/* Carousel for large images */}
                <MDBCarousel showControls showIndicators fade interval={2000}>
                  {selectedPost.imgSrc.map((src, index) => (
                    <MDBCarouselItem itemId={index + 1} key={index}>
                      <img
                        alt={`Slide ${index + 1}`}
                        loading="lazy"
                        src={src}
                        className="d-block w-100"
                        style={{ borderRadius: "10px", height: "500px" }}
                      />
                    </MDBCarouselItem>
                  ))}
                </MDBCarousel>

                <div
                  className="blog-sidebar-post-content"
                  style={{ background: "#f7f7f7" }}
                >
                  <h4
                    className="blog-post-title"
                    style={{ fontWeight: "bold", color: "#003563" }}
                  >
                    {selectedPost.title}
                  </h4>
                  <p style={{ marginTop: "20px" }}>
                    {selectedPost.description}
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div className="sidebar-wrap">
          <div className="w-dyn-list">
            <div role="list" className="blog-sidebar-right w-dyn-items">
              {caseStudies.map((study) => (
                <div
                  role="listitem"
                  className="blog-sidebar-item w-dyn-item"
                  key={study.id}
                  onClick={() => setSelectedPost(study)}
                >
                  <article className="article">
                    <p
                      className="blog-sidebar-post-category"
                      style={{ textDecoration: "none" }}
                    >
                      {study.keywords[0]}
                    </p>
                    <img
                      alt="blog"
                      loading="lazy"
                      src={study.imgSrc[0]}
                      className="sidebar-post-image"
                      style={{ borderRadius: "10px" }}
                    />
                    <div
                      className="blog-sidebar-post-content"
                      style={{ background: "#f7f7f7" }}
                    >
                      <h5
                        className="blog-post-title"
                        style={{ fontWeight: "bold", color: "#003563" }}
                      >
                        {study.title}
                      </h5>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
