import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // Rotate every 3 seconds
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }
  ]
};

const RecentProjects = ({title, description, projects}) => {
  return (
    <div style={{background:'#f7f7f7'}}>
    <div className="container py-5" style={{background:'#f7f7f7'}}>
    <h1 className="heading" style={{textAlign:'center',marginTop:'50px'}}>{title}</h1>
    <p style={{textAlign:'center'}}>{description}</p>
      <Slider {...sliderSettings} style={{maxHeight:'550px'}}>
        {projects.map((study) => (
          <div key={study.id} className="case-study-card-wrapper">
            <div className="case-study-card">
              <img
                src={study.imgSrc}
                alt={study.title}
                className="case-study-img"
              />
              <div className="case-study-content">
              <div className="case-study-keywords">
                  {study.keywords.map((keyword, i) => (
                    <span key={i} className="case-study-keyword badge" style={{color:'#0398f8'}}>
                      {keyword}
                    </span>
                  ))}
                </div>
                <h5 className="case-study-title">{study.title}</h5>
                <p className="case-study-description">{study.description}</p>
                
              </div>
            </div>
            <hr className="case-study-divider" />
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default RecentProjects;
