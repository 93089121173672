import React,{useState,useEffect} from 'react'
import {
    MDBContainer,
    MDBInput,
    MDBBtn,
    MDBTextArea,
} from 'mdb-react-ui-kit';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function Contact() {
  const [phone, setPhone] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkMobileView);

    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <div style={{ marginTop: '150px',marginBottom:"150px" }}>
        <h2 style={{textAlign:"center",fontSize:"50px",fontWeight:"bolder",color:"#212529"}}>Let's discuss your project</h2>
        <p style={{color:"#7987a1",textAlign:"center"}}>We are committed to understanding your requirements and crafting a tailored solution that aligns with your goals.</p>
        <center>
        <div style={{width:!isMobile?"600px":"400px"}}>
            <MDBContainer style={{textAlign:"left"}}>
                <div>
                    <label htmlFor="" style={{fontWeight:"500"}}>First Name <span style={{color:'red'}}>*</span></label>
                    <MDBInput id='firstName' required style={{backgroundColor:"#f5f8fa"}}/>
                </div>
                <div style={{marginTop:"10px"}}>
                    <label htmlFor="" style={{fontWeight:"500"}}>Last Name <span style={{color:'red'}}>*</span></label>
                    <MDBInput id='firstName' required style={{backgroundColor:"#f5f8fa"}}/>
                </div>
                <div style={{marginTop:"10px"}}>
                    <label htmlFor="" style={{fontWeight:"500"}}>Email <span style={{color:'red'}}>*</span></label>
                    <MDBInput id='firstName' required style={{backgroundColor:"#f5f8fa"}}/>
                </div>
                <div style={{marginTop:"10px"}}>
                    <label htmlFor="" style={{fontWeight:"500"}}>Contact <span style={{color:'red'}}>*</span></label>
                    <PhoneInput
                        country={'pk'} 
                        value={phone}
                        onChange={handlePhoneChange}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                        }}
                        inputStyle={{
                            backgroundColor: '#f5f8fa',
                            width: '100%',
                            zIndex: 2,  
                            borderRadius: '4px',
                            padding: '10px',
                            border: '1px solid #ced4da',
                        }}
                        containerStyle={{
                            width: '100%',
                            zIndex: 2,
                        }}
                    />
                    <div style={{marginTop:"10px"}}>
                        <label htmlFor="" style={{fontWeight:"500"}}>Company <span style={{color:'red'}}>*</span></label>
                        <MDBInput id='firstName' required style={{backgroundColor:"#f5f8fa"}}/>
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <label htmlFor="" style={{fontWeight:"500"}}>Region <span style={{color:'red'}}>*</span></label>
                        <select class="form-select" aria-label="Default select example" style={{backgroundColor:"#f5f8fa"}}>
                            <option selected>Please Select</option>
                            <option value="Middle East & North Afric">Middle East & North Africa</option>
                            <option value="USA">USA</option>
                            <option value="Canada">Canada</option>
                            <option value="Kingdom of Saudia Arabia">Kingdom of Saudia Arabia</option>
                            <option value="Australia & New Zealand">Australia & New Zealand</option>
                            <option value="Asia">Asia</option>
                            <option value="Europe">Europe</option>
                            <option value="Rest of World">Rest of World</option>
                        </select>
                    </div>
                    <div style={{marginTop:"10px"}}>
                        <label htmlFor="" style={{fontWeight:"500"}}>Project Details <span style={{color:'red'}}>*</span></label>
                        <MDBTextArea id='firstName' required style={{backgroundColor:"#f5f8fa"}}></MDBTextArea>
                    </div>
                    <MDBBtn style={{backgroundColor:"#003466",border:"none",width:"100px",fontWeight:"bold",marginTop:"20px",padding:"8px"}}>Submit</MDBBtn>
                </div>
            </MDBContainer>
        </div>
        </center>
    </div>
  )
}
