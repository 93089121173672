import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: "Ashkan (Ash)",
    rank: "CEO at Code Delirium",
    text: "TIERS Limited's innovative approach and expertise have made them an indispensable partner, transforming our projects into successful realities.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/2Y7D9JS4krqAwKQy2bnTL8Hqfg.png?scale-down-to=512",
  },
  {
    name: "John Doe",
    rank: "CEO of FutureSoft",
    text: "Working with TIERS Limited has been a game-changer. Their dedication to top-notch solutions consistently exceeds our expectations.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/DqlCOuEt50xoB9OtRSkK9tDWTjQ.png",
  },
  {
    name: "Sarah Johnson",
    rank: "Marketing Director at Creatives Inc.",
    text: "TIERS Limited consistently surpasses expectations with outstanding service and innovative solutions, setting new industry standards.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/Mp7qaShG98j2qmmBNdftQVFjYag.png?scale-down-to=512",
  },
  {
    name: "Michael Brown",
    rank: "VP of Operations at Global Ventures",
    text: "TIERS Limited's team is skilled and responsive, delivering exceptional results by understanding our challenges.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/2Y7D9JS4krqAwKQy2bnTL8Hqfg.png?scale-down-to=512",
  },
  {
    name: "Linda Carter",
    rank: "Head of Product Development at TechMasters",
    text: "Partnering with TIERS Limited was one of our best decisions. Their attention to detail and innovative solutions deliver exceptional results.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/uNyXIhuGNBdSRHCDQcDtk8Wtjk.png?scale-down-to=512",
  },
  {
    name: "James Wilson",
    rank: "COO at NextGen Solutions",
    text: "TIERS Limited excels at turning complex challenges into effective solutions. Their expertise has been key to our success.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/DqlCOuEt50xoB9OtRSkK9tDWTjQ.png",
  },
  {
    name: "Patricia Adams",
    rank: "CEO of Creative Minds",
    text: "TIERS Limited consistently delivers outstanding results. Their professionalism and understanding of our vision are crucial to our success.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/2Y7D9JS4krqAwKQy2bnTL8Hqfg.png?scale-down-to=512",
  },
  {
    name: "Robert Clark",
    rank: "Head of Digital Transformation at Innovators Hub",
    text: "TIERS Limited's exceptional service and technical expertise have been invaluable to our digital transformation.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/DqlCOuEt50xoB9OtRSkK9tDWTjQ.png",
  },
  {
    name: "Nancy Green",
    rank: "Director of IT at SmartTech Solutions",
    text: "TIERS Limited consistently delivers innovative solutions. Their professional approach has significantly enhanced our operations.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/Mp7qaShG98j2qmmBNdftQVFjYag.png?scale-down-to=512",
  },
  {
    name: "William King",
    rank: "Senior Manager at Enterprise Innovations",
    text: "TIERS Limited's commitment to excellence is evident in every project. Their expertise delivers exceptional results.",
    rating: 5,
    avatar:
      "https://framerusercontent.com/images/2Y7D9JS4krqAwKQy2bnTL8Hqfg.png?scale-down-to=512",
  },
];

const ReviewSection = () => {
  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container my-5 review-section-container">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} style={{ padding: "20px", background: "#fff" }}>
            <div className="review-card">
              <div
                className="row align-items-center"
                style={{ padding: "0px", backgroundColor: "#f7f7f7" }}
              >
                {/* Left Column - Image */}
                <div className="col-md-3">
                  <img
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    style={{
                      width: "100%",
                      maxWidth: "100px",
                      height: "auto",
                      borderRadius: "50%",
                      margin: "20px",
                    }}
                  />
                </div>

                {/* Right Column - Text */}
                <div className="col-md-9" style={{ position: "relative" }}>
                  <div
                    style={{
                      backgroundColor: "#f7f7f7",
                      padding: "30px 20px",
                      borderRadius: "8px",
                    }}
                  >
                    <div className="rating">
                      {Array(testimonial.rating)
                        .fill()
                        .map((_, i) => (
                          <span key={i} style={{ color: "#0398f8" }}>
                            ⭐
                          </span>
                        ))}
                    </div>
                    <p className="review-description" style={{ color: "#333" }}>
                      {testimonial.text}
                    </p>
                    <p
                      className="reviewer-name"
                      style={{
                        fontWeight: "bold",
                        color: "#333",
                        margin: "0px",
                      }}
                    >
                      {testimonial.name}
                    </p>
                    <p
                      className="reviewer-rank"
                      style={{ color: "#666", margin: "0px" }}
                    >
                      {testimonial.rank}
                    </p>
                  </div>
                  <img
                    src="../assets/review.png"
                    alt=""
                    style={{
                      position: "absolute",
                      bottom: "30px", // Adjust the distance from the bottom
                      right: "30px", // Adjust the distance from the right
                      width: "50px", // Adjust the size of the image
                      height: "auto",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      <style>
        {`
            .review-card {
              background-color: #f8f9fa;
              border-radius: 8px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              margin: 0 10px;
            }
  
            .slick-slide {
              margin: 0 0px;
            }
  
            .slick-prev {
              left: 10px;
            }
  
            .slick-next {
              right: 10px;
            }
  
            .slick-dots {
              bottom: 10px; /* Adjust this value to your needs */
              position: relative;
            }
  
            .slick-dots li button:before {
              color: #000;
            }
  
            .slick-dots .slick-active button:before {
              color: #0398f8;
            }
              .slick-dots li button:before {
                  color: #000;
              }
          `}
      </style>
    </div>
  );
};

export default ReviewSection;
