import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Features = ({
  title,
  description,
  title1,
  description1,
  listItems, // Added list items prop
}) => {
  return (
    <div className="container my-5">
      <div className="row align-items-center">
        {/* Title and Description Column */}
        <div className="col-md-6">
          <div style={{ padding: "0px 5%" }}>
            <h1
              className="heading"
              style={{ margin: "0px 0px", fontWeight: "500" }}
            >
              {title}
            </h1>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                textAlign: "left",
                maxWidth: "100%",
                margin: "15px 0px",
                lineHeight: "1.6",
                textAlign:'justify'
              }}
            >
              {description}
            </p>
          </div>
        </div>

        {/* List Column */}
        <div className="col-md-6">
          <div style={{ padding: "0px 5%" }}>
            <h1
              className="heading"
              style={{ margin: "0px 0px", fontWeight: "500" }}
            >
              {title1}
            </h1>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "normal",
                textAlign: "left",
                maxWidth: "100%",
                margin: "15px 0px",
                lineHeight: "1.6",
                color:'#000'
              }}
            >
              {description1}
            </p>
            {/* <ul style={{ paddingLeft: "0", listStyleType: "none" }}> */}
              {listItems.map((item, index) => (
                <li
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src="../assets/checkmark.png"
                    alt="Tick Icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "normal",
                      color: "#000",
                      textAlign:'justify'
                    }}
                  >
                    {item}
                  </span>
                </li>
              ))}
            {/* </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
