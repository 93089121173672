import React, { useState, useEffect } from "react";
import Intro from "../Components/Services.jsx/Intro";
import Features from "../Components/Services.jsx/Features";
import WhyChooseUs from "../Components/Services.jsx/WhyChooseUs";
import RecentProjects from "../Components/Services.jsx/RecentProjects";
import ContactUs from "../Components/Home/ContactUs";
import WorkingProcess from "../Components/Services.jsx/WorkingProcess";
import Cookies from "js-cookie";
import CaseStudies from "../Components/Services.jsx/CaseStudies";

const webDevelopment = {
  intro: {
    mainTitle: "Web Development",
    tagline: "Crafting Innovative Digital Experiences",
    serviceTitle: "Service Description",
    description:
      "At our company, we are dedicated to delivering top-tier web development solutions tailored to meet the unique needs of your business. Our expertise spans a wide range of web technologies and practices, ensuring that we create high-performing websites that are not only visually appealing but also functionally robust. We specialize in designing and developing responsive websites that offer seamless user experiences across all devices, from desktops to smartphones. Our services include custom website design, where we craft unique, eye-catching layouts that reflect your brand’s identity and values. Our development process involves implementing cutting-edge technologies to build dynamic, interactive sites with optimized performance and security. Additionally, we offer ongoing maintenance and support to ensure your website remains up-to-date with the latest standards and trends. Whether you need a new website from scratch, a redesign of an existing site, or specific features and functionalities to enhance your online presence, our team of experienced developers is here to help you achieve your goals. Our commitment to excellence and attention to detail guarantee that your website will stand out in a competitive digital landscape and effectively engage your audience.",
  },
  features: {
    headingText: "Simplify Tech Challenges with Our Responsive Help Desk Team",
    points: [
      "Front-end Development",
      "Back-end Development",
      "Content Management System (CMS) Integration",
      "E-commerce Features",
      "Responsive Design",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Projects",
    description:
      "Explore our recent projects showcasing the impact of our work.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Demystifying IT Services: Exploring Tech Trends",
        description:
          "This case study delves into the latest trends in IT services and how they are shaping the tech landscape, providing valuable insights and key outcomes of our project.",
        keywords: ["Sales Revenue", "Website Traffic", "Online Presence"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Unlocking Business Potential: The Role Of IT Services Today",
        description:
          "Explore how modern IT services are unlocking business potential by enhancing operational efficiency and driving growth, and discover the impact on client businesses.",
        keywords: ["Sales Revenue"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Tech Insights: How IT Services Drive Modern Business",
        description:
          "Gain insights into how IT services are driving modern business success, overcoming challenges, and leveraging technology to achieve significant outcomes.",
        keywords: ["Online Presence", "Website Traffic"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Transforming A Small Retailer With E-Commerce Integration",
        description:
          "This case study demonstrates how integrating e-commerce solutions transformed a small retailer, enhancing their online presence and increasing sales revenue.",
        keywords: ["Online Presence", "Website Traffic"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Enhancing Customer Engagement Through Personalization",
        description:
          "Discover how personalization techniques were employed to enhance customer engagement, drive online presence, and achieve better business outcomes.",
        keywords: ["Online Presence", "Website Traffic"],
      },
    ],
  },
};


const mobileAppDevelopment = {
  intro: {
    mainTitle: "Mobile App Development",
    tagline: "Building Cutting-Edge Mobile Solutions",
    serviceTitle: "Service Description",
    description:
      "We specialize in creating high-performance mobile applications tailored to meet your business needs. Our team of expert developers is proficient in building apps for both Android and iOS platforms, ensuring a seamless user experience. We focus on crafting intuitive interfaces and robust functionalities that drive user engagement and enhance your brand’s digital presence. Whether you're looking to create a new app from scratch or improve an existing one, our end-to-end mobile app development services are designed to deliver top-notch solutions. From concept to launch, we work closely with you to understand your vision and translate it into a functional, user-friendly app that stands out in the competitive mobile landscape.",
  },
  features: {
    headingText: "Stay Ahead with Our Comprehensive Mobile Development Services",
    points: [
      "Native Android and iOS Development",
      "Cross-Platform App Development",
      "User Interface (UI) & User Experience (UX) Design",
      "App Store Optimization (ASO)",
      "App Maintenance & Support",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Mobile App Projects",
    description:
      "Check out some of our recent mobile app development projects that highlight our expertise and innovative approach.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Revolutionizing E-Commerce with a Custom Mobile App",
        description:
          "This project involved developing a custom mobile app that revolutionized e-commerce for our client, enhancing user engagement and driving sales.",
        keywords: ["Mobile App", "E-Commerce", "User Engagement"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Boosting Productivity with an Enterprise Mobile Solution",
        description:
          "We developed an enterprise mobile app that significantly boosted productivity by streamlining business processes and improving communication.",
        keywords: ["Enterprise App", "Productivity", "Business Processes"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Enhancing Customer Experience with a Retail Mobile App",
        description:
          "Our team created a mobile app for a retail client, focusing on enhancing customer experience and driving repeat business.",
        keywords: ["Customer Experience", "Retail App", "Repeat Business"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Innovative Mobile Solutions for Healthcare Providers",
        description:
          "We developed a mobile app for healthcare providers, offering innovative solutions that improve patient care and streamline operations.",
        keywords: ["Healthcare App", "Patient Care", "Innovation"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Transforming Education with a Mobile Learning App",
        description:
          "This project involved creating a mobile learning app that transformed the educational experience for students and educators alike.",
        keywords: ["Mobile Learning", "Education", "Student Engagement"],
      },
    ],
  },
};



const generativeAI = {
  intro: {
    mainTitle: "Generative AI Solutions",
    tagline: "Harnessing the Power of AI to Innovate",
    serviceTitle: "Service Description",
    description:
      "Our Generative AI solutions are at the forefront of technology, enabling businesses to leverage artificial intelligence to create, optimize, and innovate. We specialize in developing AI models that can generate content, automate processes, and provide insights that were previously unimaginable. Whether it's creating unique marketing content, optimizing workflows through intelligent automation, or building AI-powered products, our expertise ensures that you stay ahead of the curve. Our team of AI specialists works closely with you to understand your goals and implement AI-driven solutions that are tailored to your specific needs. With a focus on ethical AI practices and cutting-edge research, we deliver solutions that not only enhance productivity but also drive business growth in new and exciting ways.",
  },
  features: {
    headingText: "Unlock New Possibilities with Our AI-Driven Solutions",
    points: [
      "Content Generation",
      "AI-Powered Automation",
      "Predictive Analytics",
      "Natural Language Processing (NLP)",
      "Custom AI Model Development",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Generative AI Projects",
    description:
      "Discover how our Generative AI solutions have transformed businesses and unlocked new potentials in various industries.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Automating Content Creation with AI",
        description:
          "In this project, we developed an AI model that automates content creation, significantly reducing time and effort for marketing teams while maintaining high-quality outputs.",
        keywords: ["AI", "Content Creation", "Automation"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Enhancing Customer Insights with Predictive Analytics",
        description:
          "We implemented a predictive analytics solution powered by AI that helps businesses gain deeper insights into customer behavior and trends, enabling more effective decision-making.",
        keywords: ["Predictive Analytics", "Customer Insights", "AI"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Transforming Customer Support with NLP",
        description:
          "Our team developed an NLP-powered chatbot that enhances customer support by providing instant, accurate responses to user queries, improving customer satisfaction and reducing operational costs.",
        keywords: ["NLP", "Chatbot", "Customer Support"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "AI-Driven Innovation in Product Design",
        description:
          "This project showcases how generative AI was used to optimize product design processes, allowing for rapid prototyping and innovative solutions in a competitive market.",
        keywords: ["AI", "Product Design", "Innovation"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Revolutionizing Healthcare with AI-Powered Diagnostics",
        description:
          "Our AI-powered diagnostic tools are revolutionizing healthcare by providing accurate and timely analysis, assisting medical professionals in delivering better patient care.",
        keywords: ["AI", "Healthcare", "Diagnostics"],
      },
    ],
  },
};



const customDevelopment = {
  intro: {
    mainTitle: "Custom Software Development",
    tagline: "Tailored Solutions for Unique Business Needs",
    serviceTitle: "Service Description",
    description:
      "Our Custom Software Development services are designed to create tailored solutions that perfectly align with your business objectives and operational requirements. We specialize in developing bespoke software applications that address specific challenges, streamline processes, and provide a competitive edge. Our team of experienced developers works closely with you to understand your unique needs, ensuring that the final product is not only functional but also scalable and future-proof. Whether you're looking to build a new system from scratch, enhance an existing application, or integrate diverse technologies, we deliver solutions that are customized to your exact specifications. Our development process focuses on agility, allowing us to adapt to changing requirements and deliver high-quality software on time and within budget. With our custom development services, you can drive efficiency, innovation, and growth, empowering your business to thrive in a dynamic digital landscape.",
  },
  features: {
    headingText: "Empower Your Business with Tailored Software Solutions",
    points: [
      "Custom Application Development",
      "System Integration",
      "Enterprise Software Solutions",
      "Legacy System Modernization",
      "Scalable Architecture Design",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Custom Development Projects",
    description:
      "Explore how our custom software solutions have empowered businesses to achieve their unique goals and drive success.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Building a Custom CRM for Enhanced Customer Engagement",
        description:
          "We developed a custom CRM system tailored to our client's specific needs, enabling better customer management, improved sales processes, and enhanced customer engagement.",
        keywords: ["Custom CRM", "Customer Engagement", "Sales Process"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Streamlining Operations with Custom ERP Solutions",
        description:
          "Our team created a custom ERP solution that streamlined business operations, integrated multiple departments, and provided real-time insights into key performance metrics.",
        keywords: ["ERP", "Operations", "Integration"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Transforming Retail Experience with Tailored E-commerce Platform",
        description:
          "We designed and developed a custom e-commerce platform that catered to the specific needs of our client's retail business, enhancing user experience and boosting online sales.",
        keywords: ["E-commerce", "Retail", "User Experience"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Legacy System Modernization for a Leading Enterprise",
        description:
          "This project involved modernizing a legacy system for a leading enterprise, ensuring seamless integration with modern technologies and enhancing system performance.",
        keywords: ["Legacy System", "Modernization", "Enterprise"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Custom Software for Advanced Data Analytics",
        description:
          "We developed custom software that enabled advanced data analytics for our client, providing actionable insights and driving data-driven decision-making across the organization.",
        keywords: ["Data Analytics", "Custom Software", "Insights"],
      },
    ],
  },
};



const digitalMarketing = {
  intro: {
    mainTitle: "Digital Marketing",
    tagline: "Driving Growth with Innovative Digital Strategies",
    serviceTitle: "Service Description",
    description:
      "Our Digital Marketing services are designed to help businesses navigate the digital landscape and achieve measurable growth. We specialize in crafting data-driven strategies that leverage the latest trends and technologies to reach your target audience effectively. From search engine optimization (SEO) and pay-per-click (PPC) advertising to social media marketing and content creation, our comprehensive approach ensures that your brand stands out in a crowded marketplace. We focus on understanding your business goals and developing customized campaigns that deliver results, whether it's increasing website traffic, boosting online sales, or enhancing brand awareness. Our team of digital marketing experts is dedicated to staying ahead of the curve, continuously optimizing campaigns to maximize ROI and drive sustainable growth. Partner with us to transform your digital presence and achieve your business objectives through strategic, results-oriented marketing.",
  },
  features: {
    headingText: "Elevate Your Brand with Our Digital Marketing Expertise",
    points: [
      "Search Engine Optimization (SEO)",
      "Pay-Per-Click (PPC) Advertising",
      "Social Media Marketing",
      "Content Marketing",
      "Email Marketing Campaigns",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Digital Marketing Campaigns",
    description:
      "Discover how our innovative digital marketing strategies have helped clients achieve their goals.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Boosting E-commerce Sales with Targeted PPC Campaigns",
        description:
          "We executed targeted PPC campaigns that significantly increased e-commerce sales for our client, delivering a high return on ad spend (ROAS) and enhanced brand visibility.",
        keywords: ["PPC", "E-commerce", "Sales Growth"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Driving Organic Traffic through SEO Optimization",
        description:
          "Our SEO strategies improved organic search rankings for our client's website, resulting in increased traffic, higher engagement, and a significant boost in lead generation.",
        keywords: ["SEO", "Organic Traffic", "Lead Generation"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Enhancing Brand Presence with Social Media Campaigns",
        description:
          "We developed and managed social media campaigns that increased brand awareness, engaged the audience, and fostered community growth for our client across multiple platforms.",
        keywords: ["Social Media", "Brand Awareness", "Engagement"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Content Marketing Strategy for Thought Leadership",
        description:
          "Our content marketing efforts positioned our client as a thought leader in their industry, through the creation and distribution of high-quality, value-driven content.",
        keywords: ["Content Marketing", "Thought Leadership", "Strategy"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Email Marketing for Customer Retention",
        description:
          "We designed and implemented email marketing campaigns that nurtured customer relationships, increased retention rates, and drove repeat business for our client.",
        keywords: ["Email Marketing", "Customer Retention", "Campaigns"],
      },
    ],
  },
};




const productDevelopment = {
  intro: {
    mainTitle: "Product Development",
    tagline: "Innovating to Bring Your Ideas to Life",
    serviceTitle: "Service Description",
    description:
      "Our Product Development services are focused on transforming your innovative ideas into market-ready products. We partner with you from concept to launch, providing a full range of services including market research, product design, prototyping, and testing. Our team of experienced developers and designers work collaboratively to create products that not only meet functional requirements but also deliver exceptional user experiences. Whether you're developing a new software application, a mobile app, or a physical product, our comprehensive approach ensures that every aspect of development is handled with precision and care. We utilize the latest technologies and development methodologies to accelerate time-to-market while maintaining the highest standards of quality. Our commitment to continuous improvement and innovation helps us deliver products that are not only technologically advanced but also aligned with your business goals. Let us help you navigate the complexities of product development and bring your vision to reality.",
  },
  features: {
    headingText: "Building the Future with Comprehensive Product Development",
    points: [
      "Concept Development & Validation",
      "User-Centered Design",
      "Rapid Prototyping",
      "Agile Development",
      "Quality Assurance & Testing",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Product Development Projects",
    description:
      "Explore our recent product development projects that demonstrate our expertise in bringing innovative products to market.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Revolutionizing Health Tech with a Cutting-Edge Mobile App",
        description:
          "Developed a health tech mobile app that enables users to monitor their wellness, providing real-time data and personalized health insights.",
        keywords: ["Health Tech", "Mobile App", "Innovation"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Developing an E-commerce Platform for Niche Markets",
        description:
          "Created an e-commerce platform tailored to niche markets, integrating advanced features like AI-driven recommendations and seamless payment processing.",
        keywords: ["E-commerce", "AI Integration", "Platform Development"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Transforming Education with an Interactive Learning Tool",
        description:
          "Designed and developed an interactive learning tool that enhances the educational experience by providing engaging, personalized content.",
        keywords: ["Education", "Interactive Tool", "Personalization"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Innovating Retail with a Custom POS System",
        description:
          "Delivered a custom Point of Sale (POS) system for a retail client, integrating inventory management and customer analytics to optimize business operations.",
        keywords: ["Retail", "POS System", "Custom Development"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Launching a Smart Home Device with IoT Capabilities",
        description:
          "Engineered a smart home device with IoT capabilities, offering users seamless control over their home environment through a user-friendly mobile interface.",
        keywords: ["IoT", "Smart Home", "Product Launch"],
      },
    ],
  },
};




const graphicDesign = {
  intro: {
    mainTitle: "Graphic Design",
    tagline: "Transforming Ideas into Visual Masterpieces",
    serviceTitle: "Service Description",
    description:
      "Our Graphic Design services are dedicated to creating visually stunning and impactful designs that capture your brand's essence and communicate your message effectively. Whether you're looking for a complete brand identity, eye-catching marketing materials, or engaging digital graphics, our team of skilled designers is here to bring your vision to life. We specialize in a wide range of design services including logo design, brochures, social media graphics, packaging design, and more. Our approach is centered around understanding your brand and your target audience to create designs that resonate and leave a lasting impression. Utilizing the latest design software and trends, we ensure that every project is not only aesthetically pleasing but also strategically aligned with your business goals. Let us help you stand out in a crowded market with designs that are both beautiful and functional.",
  },
  features: {
    headingText: "Elevating Brands with Exceptional Graphic Design",
    points: [
      "Brand Identity Design",
      "Marketing Collateral",
      "Digital Graphics",
      "Packaging Design",
      "Custom Illustrations",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent Graphic Design Projects",
    description:
      "Take a look at our recent graphic design projects that showcase our creativity and attention to detail.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Revamping Brand Identity for a Modern Tech Company",
        description:
          "Designed a fresh and modern brand identity for a tech company, including a new logo, business cards, and marketing materials that reflect their innovative approach.",
        keywords: ["Brand Identity", "Logo Design", "Marketing Materials"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Creating Eye-Catching Packaging for a Gourmet Food Line",
        description:
          "Developed visually appealing packaging design for a gourmet food line, combining vibrant colors and elegant typography to attract customers.",
        keywords: ["Packaging Design", "Gourmet Food", "Visual Appeal"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Designing Social Media Graphics for a Fashion Brand",
        description:
          "Crafted a series of engaging social media graphics for a fashion brand, enhancing their online presence and driving audience interaction.",
        keywords: ["Social Media Graphics", "Fashion Brand", "Engagement"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Developing Custom Illustrations for a Children's Book",
        description:
          "Created custom illustrations for a children's book, bringing the story to life with colorful and imaginative artwork.",
        keywords: ["Custom Illustrations", "Children's Book", "Artistic Design"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Designing a Series of Infographics for a Healthcare Company",
        description:
          "Designed a series of infographics for a healthcare company, simplifying complex information into visually engaging and easy-to-understand graphics.",
        keywords: ["Infographics", "Healthcare", "Visual Communication"],
      },
    ],
  },
};




const devOps = {
  intro: {
    mainTitle: "DevOps",
    tagline: "Streamlining Development and Operations for Maximum Efficiency",
    serviceTitle: "Service Description",
    description:
      "Our DevOps services bridge the gap between development and operations, enabling faster delivery of high-quality software. We focus on automating processes, improving collaboration, and fostering a culture of continuous integration and delivery. Our services include infrastructure as code, automated testing, CI/CD pipeline setup, monitoring, and performance optimization. By leveraging the latest DevOps tools and methodologies, we help your organization achieve greater agility, reduce time-to-market, and enhance the reliability of your software products. Our team of DevOps experts works closely with your development and IT teams to ensure seamless integration and alignment with your business objectives. Trust us to transform your software delivery process and drive innovation through effective DevOps practices.",
  },
  features: {
    headingText: "Enhancing Your Software Delivery with DevOps Excellence",
    points: [
      "Infrastructure as Code",
      "CI/CD Pipeline Automation",
      "Continuous Monitoring",
      "Performance Optimization",
      "Collaboration Enhancement",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent DevOps Projects",
    description:
      "Explore our recent projects that highlight our expertise in implementing efficient and scalable DevOps solutions.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Automating Infrastructure for a Retail Company",
        description:
          "Implemented Infrastructure as Code (IaC) for a retail company, automating their cloud infrastructure deployment and management, leading to improved efficiency and cost savings.",
        keywords: ["Infrastructure as Code", "Retail", "Automation"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Setting Up CI/CD Pipelines for a Tech Firm",
        description:
          "Developed and integrated continuous integration and continuous delivery (CI/CD) pipelines for a tech firm, enabling faster and more reliable software releases.",
        keywords: ["CI/CD Pipelines", "Tech Firm", "Continuous Delivery"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Optimizing Application Performance for a Financial Service Provider",
        description:
          "Enhanced the performance of a financial application by implementing monitoring tools and optimization techniques, resulting in improved user experience and system reliability.",
        keywords: ["Performance Optimization", "Financial Services", "Monitoring"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Collaborative DevOps Culture for a Software Company",
        description:
          "Facilitated the adoption of a DevOps culture in a software company, improving collaboration between development and operations teams and streamlining workflows.",
        keywords: ["DevOps Culture", "Collaboration", "Software Company"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Real-Time Monitoring for a Logistics Provider",
        description:
          "Deployed real-time monitoring solutions for a logistics provider, enabling proactive issue resolution and maintaining service continuity.",
        keywords: ["Real-Time Monitoring", "Logistics", "Proactive Resolution"],
      },
    ],
  },
};



const uiUx = {
  intro: {
    mainTitle: "UI/UX Design",
    tagline: "Crafting Intuitive and Engaging User Experiences",
    serviceTitle: "Service Description",
    description:
      "Our UI/UX design services focus on creating visually appealing and user-friendly interfaces that enhance user engagement and satisfaction. We combine aesthetics with functionality to deliver designs that not only look great but also provide seamless user experiences. Our services include user research, wireframing, prototyping, visual design, and usability testing. We work closely with your team to understand your target audience and business goals, ensuring that the final product resonates with users and meets your objectives. With a user-centered approach and a keen eye for detail, our team of UI/UX designers crafts interfaces that are not only beautiful but also intuitive and easy to navigate. Trust us to elevate your digital products through exceptional design and user experience.",
  },
  features: {
    headingText: "Elevating Your Digital Presence with Thoughtful UI/UX Design",
    points: [
      "User Research and Analysis",
      "Wireframing and Prototyping",
      "Visual Design",
      "Usability Testing",
      "Interaction Design",
    ],
    imageUrl:
      "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75",
  },
  recentProjects: {
    title: "Recent UI/UX Design Projects",
    description:
      "Explore our recent projects that showcase our expertise in creating user-centered designs that drive engagement and satisfaction.",
    projects: [
      {
        id: 1,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem1.png&w=3840&q=75",
        title: "Redesigning a Mobile App for a HealthTech Startup",
        description:
          "Redesigned the user interface of a mobile app for a HealthTech startup, improving user engagement and simplifying navigation to enhance the overall user experience.",
        keywords: ["UI Redesign", "HealthTech", "User Engagement"],
      },
      {
        id: 2,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem5.png&w=3840&q=75",
        title: "Creating a User-Centered Website for an E-Commerce Platform",
        description:
          "Developed a user-centered website design for an e-commerce platform, focusing on enhancing usability and visual appeal to drive conversions and customer satisfaction.",
        keywords: ["User-Centered Design", "E-Commerce", "Usability"],
      },
      {
        id: 3,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem3.png&w=3840&q=75",
        title: "Prototyping a SaaS Dashboard for a B2B Company",
        description:
          "Created an interactive prototype for a SaaS dashboard aimed at B2B clients, streamlining complex data into an intuitive and user-friendly interface.",
        keywords: ["Prototyping", "SaaS", "B2B"],
      },
      {
        id: 4,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75",
        title: "Conducting Usability Testing for a Fintech App",
        description:
          "Performed extensive usability testing on a Fintech application, identifying areas for improvement and refining the design to better meet user needs and expectations.",
        keywords: ["Usability Testing", "Fintech", "User Feedback"],
      },
      {
        id: 5,
        imgSrc:
          "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem2.png&w=3840&q=75",
        title: "Designing a Visual Identity for a Non-Profit Organization",
        description:
          "Designed a cohesive visual identity and user experience for a non-profit organization’s website, creating a strong brand presence that resonates with their mission and audience.",
        keywords: ["Visual Identity", "Non-Profit", "Brand Presence"],
      },
    ],
  },
};




export default function Services() {
  const [data, setData] = useState(webDevelopment);

    // Function to update the data based on the service type
    const updateData = () => {
      const serviceType = Cookies.get("service");
  
      if (serviceType === "Web Development") {
        setData(webDevelopment);
      } else if (serviceType === "Mobile App Development") {
        setData(mobileAppDevelopment);
      } else if (serviceType === "Generative AI") {
        setData(generativeAI);
      } else if (serviceType === "Custom Development") {
        setData(customDevelopment);
      } else if (serviceType === "Digital Marketing") {
        setData(digitalMarketing);
      } else if (serviceType === "Product Development") {
        setData(productDevelopment);
      } else if (serviceType === "Graphic Design") {
        setData(graphicDesign);
      } else if (serviceType === "DevOps") {
        setData(devOps);
      } else if (serviceType === "UI/UX") {
        setData(uiUx);
      }
    };
  
    useEffect(() => {
      // Set up an interval to check the service type every second
      const intervalId = setInterval(updateData, 1000);
  
      // Run the updateData function once immediately on mount
      updateData();
  
      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }, []);

  return (
    <div>
      <Intro
        mainTitle={data.intro.mainTitle}
        tagline={data.intro.tagline}
        serviceTitle={data.intro.serviceTitle}
        description={data.intro.description}
      />
      <Features
        headingText={data.features.headingText}
        points={data.features.points}
        imageUrl={data.features.imageUrl}
      />
      <WhyChooseUs />
      <WorkingProcess />
      {/* <RecentProjects
        title={data.recentProjects.title}
        description={data.recentProjects.description}
        projects={data.recentProjects.projects}
      /> */}
      <CaseStudies/>
      <ContactUs />
    </div>
  );
}
