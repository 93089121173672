import React from 'react';

const Intro = () => {
  return (
    <div style={{background: 'linear-gradient(to top, #fff, #9ed7fc)',paddingTop:'150px'}}>
    <div className="container" style={{marginBottom:'50px',background: '#00000000'}}>
      <h1 className="heading text-center">Case Studies</h1>
      <p className="intro-tagline">From Clients Who Achieved Excellence with TIERS</p>

      <style>
        {`
          .intro-container {
            padding: 20px;
            text-align: center;
          }

          .intro-title {
            font-size: 3rem; /* Large font size for large screens */
            margin: 0;
            padding-bottom: 10px;
            text-align:center;
            color:#000;
          }

          .intro-tagline {
            margin: 0;
            font-size: 18px;
            padding-bottom: 20px;
            text-align:center;
            color:#646570;
          }

          .service-title {
            text-align: left;
            font-size: 2rem; /* Large font size for large screens */
            margin: 20px 0 10px;
            padding: 0;
            color:#000;
            font-weight:normal;
          }

          .service-description {
            text-align: left;
            font-size: 1rem; /* Regular font size for large screens */
            margin: 0;
            padding: 0;
          }

          @media (max-width: 768px) {
            .intro-title {
              font-size: 1.8rem; /* Smaller font size for small screens */
            }

            .intro-tagline {
              font-size: 1rem;
            }

            .service-title {
              font-size: 1.5rem; /* Smaller font size for small screens */
            }

            .service-description {
              font-size: 0.9rem; /* Smaller font size for small screens */
            }
          }
        `}
      </style>
    </div>
    </div>
  );
};

export default Intro;
