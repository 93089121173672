import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Vision() {
  return (
    <div className="vision-section py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{ padding: "20px" }}>
            <h2 className="vision-title">About Us</h2>
            <p className="vision-description" style={{ textAlign: "justify" }}>
            &quot;TIERS Limited is a leading IT services and consulting firm
              renowned for its expertise in software development, digital
              marketing, and groundbreaking product solutions. Their visionary
              team excels in creating bespoke software applications that
              transform operations and elevate user experiences. In digital
              marketing, TIERS Limited designs captivating campaigns that
              amplify online presence and drive remarkable engagement.
              <br/>
              <br/>
              Celebrated for their focus on innovation, they develop
              cutting-edge products that propel clients ahead in a competitive
              market. Dedicated to excellence, TIERS Limited empowers
              organizations to achieve exceptional growth and operational
              brilliance through strategic technology and insightful consulting.&quot;
            </p>
          </div>
          <div className="col-md-6" style={{ padding: "20px" }}>
            <h2 className="vision-title">Our Vision</h2>
            <p className="vision-description" style={{ textAlign: "justify" }}>
              &quot;At TIERS Limited, we envision becoming a global leader in
              software development by driving technological innovation and
              delivering transformative solutions. Our goal is to set new
              standards of excellence in the industry, harnessing the latest
              advancements to solve complex challenges and create unparalleled
              value for our clients.
              <br />
              <br />
              We aspire to be the partner of choice for businesses seeking
              forward-thinking technology solutions. Through our commitment to
              quality, creativity, and client-centricity, we aim to empower
              organizations to achieve their strategic goals and thrive in a
              dynamic digital landscape.&quot;
            </p>
          </div>
        </div>
      </div>
      <style jsx>{`
        .vision-title {
          font-weight: bold;
          color: #003467; /* Adjust the color as needed */
        }
        .vision-description {
          font-size: 1.1rem;
          line-height: 1.5;
          color: #000; /* Adjust the color as needed */
          font-weight: normal;
        }
      `}</style>
    </div>
  );
}
