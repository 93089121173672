import React from "react";

const features = [
  {
    logoUrl: "https://cdn-icons-png.flaticon.com/128/7442/7442972.png",
    title: "Efficient Technical Support",
    description: "Quick and effective resolution of technical issues",
  },
  {
    logoUrl: "https://cdn-icons-png.flaticon.com/128/17218/17218187.png",
    title: "Enhanced Productivity",
    description: "Time saved through prompt guidance.",
  },
  {
    logoUrl: "https://cdn-icons-png.flaticon.com/128/2801/2801923.png",
    title: "Minimized Incidents",
    description: "Consistent support to minimize disruptions.",
  },
];

export default function WhyChooseUs() {
  return (
    <div className="web-dev-intro container my-5">
      <div className="row align-items-center">
        <div className="col-md-6 d-flex justify-content-center">
          <img
            src="https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-third7.png&w=3840&q=75"
            alt="Web Development"
            className="web-dev-image"
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              borderRadius: "8px",
            }}
          />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <h2 className="heading" style={{ fontWeight: "500" }}>
            Elevate Productivity with Our Expert Help Desk Services
          </h2>
          <div style={{borderBottom:'1px solid #e4e4e4',paddingBottom:'10px'}}>
            <p style={{ color: "#000", fontWeight: "normal", marginTop: '5px' }}>
              Welcome to Bytewave, your trusted companion in the realm of IoT
              services. We take pride in being a dedicated partner, committed to
              delivering state-of-the-art IoT solutions that propel the future of
              your business.
            </p>
          </div>

          <div style={{ marginTop: '20px' }}>
            {features.map((feature, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px',marginTop: '10px', }}>
                <img
                  src={feature.logoUrl}
                  alt={`Logo for ${feature.title}`}
                  style={{ width: '40px', height: '40px', marginRight: '15px' }}
                />
                <div>
                  <h4 style={{ margin: '0', color: '#000' }}>{feature.title}</h4>
                  <p style={{ margin: '0', color: '#000', fontWeight:'normal' }}>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <style>
        {`
          .point-item {
            font-size: 1.4rem;
            margin-top: 15px;
            font-weight: normal;
          }

          @media (max-width: 768px) {
            .heading {
              font-size: 1.5rem;
            }

            .point-item {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
}
