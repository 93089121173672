import React from 'react'
import ReviewSection from '../Home/ReviewSection'
export default function Testimonial() {
  return (
    <div className='container my-5'>
         <h1 className='heading text-center'>Testimonials</h1>
          <p style={{textAlign:'center' }}>
          Discover exceptional experiences through testimonials from our satisfied customers.
          </p>
        <ReviewSection/>
    </div>
  )
}
