import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BannerSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="container" style={{ marginTop: '140px', marginBottom: '40px' }}>
      <div className="row">
        {/* Text Section */}
        <div className="col-lg-4 col-md-12 mb-4" style={{alignItems:'center',justifyContent:'center',display:'flex',flexDirection:'column'}}>
          <h1 className="heading">SERVING THE {" "}<span style={{color:'#0398f8'}}> TECHNOLOGY</span> BETTER</h1>
          <p className="text" style={{marginTop:'30px'}}>
          With TIERS Limited,
          you can promote your all web design & development services.
          </p>
        </div>

        {/* Image Section */}
        <div className="col-lg-8 col-md-12">
          <Slider {...settings}>
            <div>
              <img
                src="../assets/11.jpg"
                alt="First slide"
                style={{ borderRadius: '30px', width: '100%' }}
              />
            </div>
            <div>
              <img
                src="../assets/22.jpg"
                alt="Second slide"
                style={{ borderRadius: '30px', width: '100%' }}
              />
            </div>
            <div>
              <img
                src="../assets/33.jpg"
                alt="Third slide"
                style={{ borderRadius: '30px', width: '100%' }}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
