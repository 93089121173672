import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const InnovationSolution = ({ subtitle, title, innovationSolution }) => {
  // Function to capitalize the first letter of each word
  const capitalizeFirstLetter = (str) => {
    return str.replace(/([A-Z])/g, ' $1').trim().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className="container my-5" style={{background: "linear-gradient(to right, #f4f6fa, #f9f7ef)", borderRadius: '20px'}}>
      <div className="row" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '50px 0px'}}>
        <div className="col-11">
          <div className="text-center mb-4">
            <h5 style={{color: '#000', fontWeight: 'normal'}}>{subtitle}</h5>
            <h1 className="heading" style={{fontWeight: '500'}}>{title}</h1>
          </div>
          <div className="row">
            {Object.entries(innovationSolution).map(([key, value], index) => (
              <div className="col-md-3 mb-4" key={index}>
                <div className="border-0 text-center">
                  <div className="card-body">
                    <h1 className="card-title" style={{color: '#003466'}}>{value.split(' ')[0]}</h1>
                    <h5 style={{color: '#000', fontWeight: 'normal'}}>{capitalizeFirstLetter(key)}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovationSolution;
