import React, { useState, useEffect} from "react";
import Intro from "../Components/Services.jsx/Intro";
import Problems from "../Components/Products/Problems";
import Solutions from "../Components/Products/Solutions";
import Benefits from "../Components/Products/Benefits";
import WhyChoose from "../Components/Products/WhyChoose";
import WorkingProcess from "../Components/Services.jsx/WorkingProcess";
import ContactUs from '../Components/Home/ContactUs'
import Cookies from 'js-cookie'

const fleetVisionX = [
    {
      type: "Intro",
      data: {
        mainTitle: "Fleet Vision X",
        tagline: "Revolutionizing Fleet Management for the Future",
        serviceTitle: "Product Description",
        description: "FleetVisionX is a cutting-edge fleet management solution designed to transform the transportation industry. Harnessing advanced technology and innovative algorithms, FleetVisionX offers comprehensive insights and real-time data analytics to optimize fleet operations, enhance safety, and increase efficiency."
      }
    },
    {
      type: "Problems",
      data: {
        headingText: "Challenges in the Transportation Industry",
        points: [
          { heading: "Inefficient Route Planning", text: "Leading to increased travel time and fuel consumption." },
          { heading: "Unplanned Maintenance", text: "Resulting in unexpected breakdowns and costly repairs." },
          { heading: "Safety Concerns", text: "Due to poor monitoring of driver behavior and vehicle conditions." },
          { heading: "High Operational Costs", text: "Caused by inefficiencies and lack of data-driven decision-making." },
          { heading: "Scalability Issues", text: "Difficulty in scaling operations to meet growing business demands." }
        ],
        imageUrl: "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75"
      }
    },
    {
      type: "Solutions",
      data: {
        title: "Elevate Productivity with Our Expert Help Desk Services",
        description: "FleetVisionX offers a comprehensive suite of features to optimize fleet management:",
        features: [
          { title: "Advanced Analytics", description: "Use real-time data to enhance fleet performance and operational efficiency." },
          { title: "Route Optimization", description: "Reduce travel time and fuel use with intelligent route planning." },
          { title: "Predictive Maintenance", description: "Prevent breakdowns and extend vehicle lifespan with proactive alerts." },
          { title: "Safety Enhancements", description: "Improve driver safety with monitoring and protocol implementation." },
          { title: "Cost Reduction", description: "Lower operational costs by eliminating inefficiencies." },
          { title: "Scalability", description: "Adapt and grow your fleet management operations seamlessly." }
        ],
        imageUrl: "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-third7.png&w=3840&q=75"
      }
    },
    {
      type: "Benefits",
      data: [
        { id: 'improved-efficiency', title: 'Improved Efficiency', description: 'Streamline your fleet operations to increase productivity and reduce downtime, ensuring your business runs smoothly.' },
        { id: 'enhanced-safety', title: 'Enhanced Safety', description: 'Implement robust safety measures to protect your drivers and vehicles, reducing the risk of accidents and associated costs.' },
        { id: 'cost-savings', title: 'Cost Savings', description: 'Achieve significant cost savings through efficient resource management, optimized routes, and proactive maintenance.' },
        { id: 'competitive-advantage', title: 'Competitive Advantage', description: 'Stay ahead in the transportation industry with innovative technology that provides a comprehensive view of your fleet operations, helping you make informed decisions and stay competitive.' }
      ]
    },
    {
      type: "WhyChoose",
      data: {
        Title: "Why Choose FleetVisionX?",
        description: "FleetVisionX stands out with its holistic approach to fleet management. By integrating state-of-the-art technology and intuitive algorithms, it offers a complete solution for optimizing fleet operations. Whether you manage a small fleet or a large-scale operation, FleetVisionX provides the tools and insights you need to succeed in today’s dynamic transportation landscape."
      }
    }
  ]



  const resoSyncer = [
    {
      type: "Intro",
      data: {
        mainTitle: "ResoSyncer",
        tagline: "Elevate Your Business Operations with ResoSyncer",
        serviceTitle: "Product Description",
        description: "ResoSyncer is a cutting-edge platform designed to revolutionize how businesses manage resources, track projects, and oversee employee data. Our comprehensive solution provides a unified approach to streamline your operations, enhance productivity, and ensure data accuracy."
      }
    },
    {
      type: "Problems",
      data: {
        headingText: "Understanding the Challenges",
        points: [
          { heading: "Resource Allocation Difficulties", text: "Companies often struggle to effectively allocate and track resources across various projects, leading to inefficiencies and underutilization." },
          { heading: "Limited Project Visibility", text: "Without real-time insights into project progress and milestones, managing deadlines and ensuring timely delivery becomes a complex task." },
          { heading: "Fragmented Employee Data", text: "Disparate systems and manual processes result in inconsistent and unreliable employee records, impacting HR operations and decision-making." }
        ],
        imageUrl: "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75"
      }
    },
    {
      type: "Solutions",
      data: {
        title: "How ResoSyncer Addresses These Challenges",
        description: "ResoSyncer provides an integrated solution that addresses these challenges with ease:",
        features: [
          { title: "Real-Time Resource Visibility", description: "Our platform offers a clear view of resource availability and allocation. Track and manage resources effortlessly, ensuring they are used optimally across all projects." },
          { title: "Advanced Task Management", description: "Seamlessly assign tasks, set and monitor milestones, and track project progress. Keep your projects on schedule and ensure every team member is aligned with project goals." },
          { title: "Comprehensive Employee Data Management", description: "Consolidate all employee information into a single, reliable system. Manage employee records, track performance, and streamline HR processes with accuracy and efficiency." }
        ],
        imageUrl: "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-third7.png&w=3840&q=75"
      }
    },
    {
      type: "Benefits",
      data: [
        { id: 'enhanced-productivity', title: 'Enhanced Productivity', description: 'Automate and streamline your processes, reducing manual workloads and freeing up valuable time for your team to focus on core activities.' },
        { id: 'improved-collaboration', title: 'Improved Collaboration', description: 'Foster better teamwork and communication with transparent task assignments and real-time updates on project status.' },
        { id: 'accurate-data-management', title: 'Accurate Data Management', description: 'Maintain a single source of truth for all resource, project, and employee data, minimizing errors and ensuring consistency.' },
        { id: 'increased-efficiency', title: 'Increased Efficiency', description: 'Integrate key functions into one platform, optimizing your operations and driving better overall performance.' }
      ]
    },
    {
      type: "WhyChoose",
      data: {
        Title: "Why ResoSyncer Stands Out",
        description: "ResoSyncer is designed with a user-friendly interface and powerful features to meet the diverse needs of modern businesses. Our platform’s intuitive design ensures quick adoption and minimal training, while its advanced capabilities support complex project and resource management needs. By choosing ResoSyncer, you invest in a solution that enhances operational efficiency, drives productivity, and supports long-term success."
      }
    }
  ];
  


  const smartBD = [
    {
      type: "Intro",
      data: {
        mainTitle: "SmartBD",
        tagline: "Intelligent Business Decisions with SmartBD",
        serviceTitle: "Product Description",
        description: "SmartBD is an innovative platform that empowers businesses with data-driven decision-making capabilities. By leveraging advanced analytics and real-time insights, SmartBD helps organizations to make informed choices, optimize operations, and achieve sustained growth."
      }
    },
    {
      type: "Problems",
      data: {
        headingText: "Business Challenges",
        points: [
          { heading: "Data Overload", text: "The overwhelming amount of data can make it difficult to extract meaningful insights." },
          { heading: "Inconsistent Reporting", text: "Disparate data sources lead to inconsistencies in reporting and analysis." },
          { heading: "Slow Decision-Making", text: "Lack of real-time data hinders timely and effective decision-making." },
          { heading: "Inefficient Operations", text: "Without actionable insights, businesses struggle to optimize their operations." }
        ],
        imageUrl: "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-second7.png&w=3840&q=75"
      }
    },
    {
      type: "Solutions",
      data: {
        title: "SmartBD Solutions",
        description: "SmartBD offers a comprehensive suite of tools designed to address these challenges and drive business success:",
        features: [
          { title: "Advanced Data Analytics", description: "Turn raw data into actionable insights to drive strategic decisions." },
          { title: "Real-Time Reporting", description: "Gain instant access to data-driven reports for timely decision-making." },
          { title: "Operational Efficiency", description: "Optimize your business processes with data-backed strategies." },
          { title: "Scalability", description: "Easily scale your data capabilities as your business grows." }
        ],
        imageUrl: "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fabout-third7.png&w=3840&q=75"
      }
    },
    {
      type: "Benefits",
      data: [
        { id: 'data-driven-decisions', title: 'Data-Driven Decisions', description: 'Empower your business with insights that drive smart, strategic decisions.' },
        { id: 'improved-efficiency', title: 'Improved Efficiency', description: 'Enhance operational efficiency through streamlined processes and informed strategies.' },
        { id: 'real-time-insights', title: 'Real-Time Insights', description: 'Stay ahead of the competition with up-to-the-minute data and analytics.' },
        { id: 'scalability', title: 'Scalability', description: 'Adapt and grow your data capabilities as your business expands.' }
      ]
    },
    {
      type: "WhyChoose",
      data: {
        Title: "Why Choose SmartBD?",
        description: "SmartBD stands out for its user-friendly interface, powerful analytics, and real-time insights. Our platform is designed to help businesses harness the power of data, enabling them to make informed decisions, optimize operations, and achieve sustained success in today’s competitive market."
      }
    }
  ];
  
  

export default function Products() {
  // State for fleetVisionX
  const [data, setData] = useState(fleetVisionX);

  const updateData = () => {
    const productType = Cookies.get("product");

    if (productType === "Fleet Vision X") {
      setData(fleetVisionX);
    } else if (productType === "ResoSyncer") {
      setData(resoSyncer);
    } else if (productType === "SmartBD") {
      setData(smartBD);
    }
  };

  useEffect(() => {
    // Set up an interval to check the product type every second
    const intervalId = setInterval(updateData, 1000);

    // Run the updateData function once immediately on mount
    updateData();

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      {data.map((section, index) => {
        switch (section.type) {
          case "Intro":
            return <Intro key={index} {...section.data} />;
          case "Problems":
            return <Problems key={index} {...section.data} />;
          case "Solutions":
            return <Solutions key={index} {...section.data} />;
          case "Benefits":
            return <Benefits key={index} benefits={section.data} />;
          case "WhyChoose":
            return <WhyChoose key={index} {...section.data} />;
          default:
            return null;
        }
      })}
      <WorkingProcess />
      <ContactUs />
    </div>
  );
}
