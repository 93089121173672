import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie"; // Ensure this is imported correctly

const cardData = [
  {
    id: 1,
    title: "Code Delirium",
    imgSrc: "../assets/code.jpg", // Replace with actual image URL
  },
  {
    id: 2,
    title: "NexTrade",
    imgSrc: "../assets/next.jpg", // Replace with actual image URL
  },
  {
    id: 3,
    title: "UNITEN",
    imgSrc: "../assets/uniten.jpg", // Replace with actual image URL
  },
  {
    id: 4,
    title: "ResoSyncer",
    imgSrc: "../assets/reso.jpg", // Replace with actual image URL
  },
  {
    id: 5,
    title: "Neuronetix",
    imgSrc: "../assets/neuro.jpg", // Replace with actual image URL
  },
  {
    id: 6,
    title: "Gringo",
    imgSrc: "../assets/gringo.jpg", // Replace with actual image URL
  },
  {
    id: 7,
    title: "Swissmine",
    imgSrc: "../assets/swissmine.jpg", // Replace with actual image URL
  },
  {
    id: 8,
    title: "Scholar Suites",
    imgSrc: "../assets/scholor.jpg", // Replace with actual image URL
  },
  {
    id: 9,
    title: "NeaterNotes",
    imgSrc: "../assets/notes.jpg", // Replace with actual image URL
  },
];

const OurWork = () => {
  return (
    <div className="container my-5" style={{ marginTop: "100px" }}>
      <h1
        className="heading"
        style={{ textAlign: "center", marginTop: "120px" }}
      >
        Our Work
      </h1>
      <p style={{ textAlign: "center" }}>
        Case studies that showcase the agency's approach, results for specific
        clients.
      </p>
      <div className="row">
        {cardData.map((card) => (
          <div
            className="col-md-4 mb-4"
            key={card.id}
            onClick={() => {
              Cookies.set("cardid", card.id, { expires: 1 });
              window.location.href = "/portfolio-detail";
            }}
          >
            <div
              className="position-relative"
              style={{
                backgroundImage: `url(${card.imgSrc})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "250px",
                borderRadius: "15px",
                margin: "0px 20px",
                marginTop: "20px",
              }}
            ></div>
            <div
              className="position-relative"
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                // margin: '20px',
                // width: '100%',
                height: "50px",
                color: "#000",
                margin: "0px 20px",
                marginTop: "0px",
                alignItems:'center',
                // justifyContent:'center',
                display:'flex'
              }}
            >
              <h4
                className="mb-0"
                style={{
                  textAlign: "left",
                  margin: "0px 0px",
                  fontWeight: "normal",
                }}
              >
                {card.title}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurWork;
