import React from 'react'
import Intro from '../Components/Portfolio/Intro'
import ECommerceIntegration from '../Components/Portfolio/ECommerceIntegration'
import LogoSection from '../Components/Home/LogoSection'
import OurWork from '../Components/Portfolio/OurWork'
import PricingSection from '../Components/Home/ContactUs'
import Testimonial from '../Components/About/Testimonial'

export default function Portfolio() {
  return (
    <div>
        <Intro/>
        <ECommerceIntegration/>
        <LogoSection/>
        <OurWork/>
        <Testimonial/>
        <PricingSection/>
    </div>
  )
}
