import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

const Team = () => {
  const teamMembers = [
    {
      name: 'Shoaib Rafique',
      title: 'Founder & CEO',
      image: '../assets/shoaib.jpg',
      linkedin: '/',
    },
    {
      name: 'Shahbaz Rafique',
      title: 'Vice President',
      image: '../assets/shahbaz.jpg',
      linkedin: '/',
    },
    {
      name: 'Syed Shoaib Abbas',
      title: 'Director of Engineering',
      image: '../assets/shoaiba.jpg',
      linkedin: '/',
    },
    {
      name: 'Ashkanm Mohaghegh',
      title: 'Head of BD',
      image: '../assets/ashkan.jpg',
      linkedin: '/',
    },
    {
      name: 'Charles Hurd',
      title: 'Head of Sales & Marketing',
      image: '../assets/charles.jpg',
      linkedin: '/',
    },
    {
      name: 'Ibtasam Amjad',
      title: 'Head of PR & Partnerships',
      image: '../assets/ibtasam.jpg',
      linkedin: '/',
    },
    {
      name: 'Jonathan Ouellet',
      title: 'Head of New Markets',
      image: '../assets/jonathan.jpg',
      linkedin: '/',
    },
  ];

  return (
    <div className="container my-5">
      <div className="row text-center mb-4">
        <div className="col-12">
          <h1 className='heading text-center' style={{ color: '#003467' }}>Meet our Leader</h1>
          <p style={{ fontSize: '20px',textAlign:'center' }}>
            Discover exceptional experiences through testimonials from our satisfied customers.
          </p>
        </div>
      </div>
      <div className="row">
        {teamMembers.map((member, index) => (
          <div className="col-md-4 col-sm-6 col-xs-12 mb-4" key={index}>
            <div className="">
                <div style={{}}>
                <img
  src={member.image}
  alt={member.name}
  className="img-fluid"
  style={{ 
    width: '100%', 
    height: 'auto', 
    aspectRatio: '1 / 1', // Maintains a square aspect ratio
    marginRight: '15px',
    borderRadius: '30px',
    objectFit: 'cover' // Ensures the image covers the entire area
  }}
/>

                </div>
              <div className="card-body d-flex align-items-center" style={{marginTop:'20px'}}>
                
                <div className="flex-grow-1">
                  <h5 className="mb-0">{member.name}</h5>
                  <p className="text-muted mb-0">{member.title}</p>
                </div>
                <a
                  // href={member.linkedin}
                  href='#'
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: 'auto' }}
                >
                  <FaLinkedin size={30} color="#0e76a8" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
