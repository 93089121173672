import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const FourPoints = () => {
  // Data array embedded in the component
  const columnsData = [
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/14535/14535794.png', // Replace with actual logo URL
      title: 'Expertise',
      description: 'Access to specialized knowledge and technology expertise for effective IT solutions.'
    },
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/3759/3759086.png',
      title: 'Cost Savings',
      description: 'Avoiding expenses associated with maintaining an in-house IT department.'
    },
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/8348/8348585.png',
      title: 'Enhanced Security',
      description: 'Robust measures to protect against cyber threats and ensure data security.'
    },
    {
      logo: 'https://cdn-icons-png.flaticon.com/128/12430/12430374.png',
      title: 'Increased Efficiency',
      description: 'Streamlined operations, automation, and proactive monitoring for improved productivity.'
    },
  ];

  return (
    <div className="container my-5">
      <div className="row">
        {columnsData.map((column, index) => (
          <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
            <div className="d-flex flex-column align-items-start">
              {column.logo && (
                <img 
                  src={column.logo} 
                  alt="Logo" 
                  className="mb-3" 
                  style={{ height: '50px', objectFit: 'contain' }} 
                />
              )}
              <h5 className="mb-2">{column.title}</h5>
              <p>{column.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FourPoints;
