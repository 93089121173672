import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const ECommerceIntegration = () => {
  return (
    <div className="container mt-5">
      <div className="shadow-lg" style={{ borderRadius: "20px" }}>
        <div className="row g-0">
          {/* Image column for mobile-first design */}
          <div className="col-md-6 order-md-2">
            <img
              src="https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75"
              alt="Card Image"
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "0px 20px 20px 0px",
              }}
            />
          </div>
          {/* Text column */}
          <div className="col-md-6 order-md-1 d-flex align-items-center justify-content-center">
            <div className="card-body text-center" style={{padding:'20px 10%'}}>
              <h1 className="heading" style={{ marginBottom: "20px",textAlign:'left' }}>
                Transforming A Small Retailer With E-Commerce Integration
              </h1>
              <p className="card-text" style={{fontSize:'18px', fontWeight:'normal'}}>
                It is a long established fact that a reader will be distracted by the readable content of
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECommerceIntegration;
