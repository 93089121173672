import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCheck  } from 'react-icons/fa';

export default function FinancialPotential() {
  return (
    <div className="financial-potential-section py-5" >
      <div className="container" style={{ background: 'linear-gradient(to top, #f4f4f4, #f4f4f4)',padding:'40px',borderRadius:'30px' }}>
        <div className="row">
          <div className="col-md-6 d-flex flex-column align-items-center" style={{margin:'20px 0px'}}>
            <h2 className="financial-potential-title mb-4">6+ Years Of History Serving The Poconos Region</h2>
            <img
              src="https://plus.unsplash.com/premium_photo-1663100794696-6b7afa02016c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fEZpbmFuY2lhbCUyMFBvdGVudGlhbHxlbnwwfHwwfHx8MA%3D%3D"
              alt="Financial Potential"
              className="img-fluid"
              style={{ borderRadius: '30px', maxWidth: '100%', height: 'auto' }}
            />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center" style={{margin:'20px 0px',padding:'0px 30px'}}>
            <div className="p-10" style={{ borderRadius: '30px', background: '#fff' }}>
              <h1 className="financial-title mb-4" style={{ fontWeight: 'bold' }}>
                Unlock your financial potential today!
              </h1>
              {/* <ul className="list-" style={{color:'#000'}}> */}
                {[
                  "Supported by experienced financial experts.",
                  "Optimizing investment portfolios and assets.",
                  "Building stable financial plans for the future.",
                  "Ensuring financial security through suitable insurance.",
                  "Optimizing investment portfolios and assets."
                ].map((text, index) => (
                  <li key={index} className="mb-2 d-flex align-items-center" style={{fontSize:'20px',color:'#000',fontWeight:'normal'}}>
                    <span style={{marginRight:'10px'}}><FaCheck  className="m-2" style={{ color: '#35adf9',fontSize:'20px' }} /></span>
                    {text}
                  </li>
                ))}
              {/* </ul> */}
              <div className="mt-4">
                <button className="btn btn-primary px-4" style={{ fontWeight:'bold',backgroundColor: '#003467', borderColor: '#003467' }}>
                  Our Services
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .financial-potential-title {
          font-size: calc(1.4rem + 1vw);
          font-weight: normal;
          color: #000;
          text-align: left;
        }
          .financial-title {
          font-size: calc(2rem + 1vw);
          font-weight: bold;
          color: #003467;
          text-align: left;
        }
        .card-title {
          font-size: calc(1.2rem + 0.5vw);
        }
        @media (max-width: 768px) {
          .financial-potential-title {
            font-size: 1.25rem;
          }
            .financial-title {
            font-size: 1.45rem;
          }
          .card-title {
            font-size: 1rem;
          }
        }
      `}</style>
    </div>
  );
}
