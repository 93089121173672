import React from "react";

export default function WhyChoose({
  Title = "Service Description",
  description = "Our services are tailored to meet the unique needs of each client. We provide top-notch solutions with a focus on innovation and efficiency.",
}) {
  return (
    <div style={{background: "#f7f7f7"}}>
      <div
        className="container py-5"
        style={{ marginBottom: "50px", background: "#f7f7f7" }}
      >
        <h2 className="service-title" style={{ fontWeight: "bold" }}>
          {Title}
        </h2>
        <p className="service-description" style={{ textAlign: "justify" }}>
          {description}
        </p>

        <style>
          {`
          .intro-container {
            padding: 20px;
            text-align: center;
          }

          .intro-title {
            font-size: 3rem; /* Large font size for large screens */
            margin: 0;
            padding-bottom: 10px;
            text-align:center;
            color:#000;
          }

          .intro-tagline {
            margin: 0;
            font-size: 18px;
            padding-bottom: 20px;
            text-align:center;
            color:#646570;
          }

          .service-title {
            text-align: left;
            font-size: 2rem; /* Large font size for large screens */
            margin: 20px 0 10px;
            padding: 0;
            color:#000;
            font-weight:normal;
          }

          .service-description {
            text-align: left;
            font-size: 1rem; /* Regular font size for large screens */
            margin: 0;
            padding: 0;
          }

          @media (max-width: 768px) {
            .intro-title {
              font-size: 1.8rem; /* Smaller font size for small screens */
            }

            .intro-tagline {
              font-size: 1rem;
            }

            .service-title {
              font-size: 1.5rem; /* Smaller font size for small screens */
            }

            .service-description {
              font-size: 0.9rem; /* Smaller font size for small screens */
            }
          }
        `}
        </style>
      </div>
    </div>
  );
}
