import React from "react";

export default function Benefits({ features, title, imageSrc }) {
  return (
    <div className="web-dev-intro container my-5" >
      <div className="row align-items-center" style={{margin:'100px 0px'}}>
        
        <div className="col-md-6 d-flex flex-column justify-content-center">
          <h1 className="heading" style={{ fontWeight: "500" }}>
            {title}
          </h1>

          <div style={{ marginTop: '20px' }}>
            {features.map((feature, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '15px',marginTop: '10px', }}>
                <img
                  src={feature.logoUrl}
                  alt={`Logo for ${feature.title}`}
                  style={{ width: '40px', height: '40px', marginRight: '15px' }}
                />
                <div>
                  <h4 style={{ margin: '0', color: '#000' }}>{feature.title}</h4>
                  <p style={{ margin: '0', color: '#000', fontWeight:'normal' }}>{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center">
          <img
            src={imageSrc}
            alt="Web Development"
            className="web-dev-image"
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              borderRadius: "20px",
            }}
          />
        </div>
      </div>

      <style>
        {`
          .point-item {
            font-size: 1.4rem;
            margin-top: 15px;
            font-weight: normal;
          }

          @media (max-width: 768px) {
            .heading {
              font-size: 1.5rem;
            }

            .point-item {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
}
