import React from 'react';

const BannerSection = () => {
  return (
    <div>
    <section className="banner-section about-banner" style={{marginTop:'100px'}}>
      <div className="w-layout-blockcontainer base-container w-container">
        <div className="home-3-banner-wrapper">
          <div className="about-us-text-container">
            <h1 className="heading-11">
              Our Journey to a leading {' '}
              <span className="primary-text" style={{color:'#0398f8'}}>Software Development Partner</span>
            </h1>
          </div>
        </div>
        <div className="images-container career">
          <div>
            <img
              src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660adbb25d37bb3d19d33e3c_jud-mackrill-Of_m3hMsoAA-unsplash.webp"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 71vw, (max-width: 991px) 52vw, (max-width: 1279px) 391.6145324707031px, (max-width: 1439px) 425.682373046875px, (max-width: 1919px) 418.22113037109375px, 22vw"
              srcSet="
                https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660adbb25d37bb3d19d33e3c_jud-mackrill-Of_m3hMsoAA-unsplash-p-500.webp 500w,
                https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660adbb25d37bb3d19d33e3c_jud-mackrill-Of_m3hMsoAA-unsplash-p-800.webp 800w,
                https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660adbb25d37bb3d19d33e3c_jud-mackrill-Of_m3hMsoAA-unsplash-p-1080.webp 1080w,
                https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660adbb25d37bb3d19d33e3c_jud-mackrill-Of_m3hMsoAA-unsplash.webp 1500w
              "
              alt="team"
              // className="about-us-header-image top"
              style={{borderRadius:'10px'}}
            />
          </div>
          {/* <div>
            <img
              src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660ae32812c74ec812c1bc20_scott-graham-5fNmWej4tAA-unsplash.webp"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 71vw, (max-width: 991px) 49vw, (max-width: 1279px) 39vw, (max-width: 1439px) 425.7374572753906px, (max-width: 1919px) 29vw, 22vw"
              srcSet="
                https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660ae32812c74ec812c1bc20_scott-graham-5fNmWej4tAA-unsplash-p-500.webp 500w,
                https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/660ae32812c74ec812c1bc20_scott-graham-5fNmWej4tAA-unsplash.webp 750w
              "
              alt="team"
              className="about-us-header-image bottom"
            />
          </div> */}
        </div>
      </div>
    </section>

    <div className="dark-bg-with-logo-not-fixed big-text" style={{background: 'radial-gradient(circle, #003467, #003467)',overflowX:'hidden'}}>
        <div className="w-layout-blockcontainer container-5 w-container">
          <div className="subtitle-wrap mt-50" style={{marginTop:'-10px'}}>
            <h4 className="section-subtitle-white">Our Mission<br /></h4>
          </div>
          <div className="mission-text" style={{fontSize:'26px',textAlign:'justify'}}>
          Empower businesses through innovative software solutions, delivering exceptional quality and value in every project. We strive to foster lasting partnerships by understanding our clients' unique needs and exceeding their expectations with our expertise and dedication.
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
