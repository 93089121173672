import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";

export default function Analytics() {
  return (
    <div className='container'>
        <div className="row">
        {/* Column 1 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid #003467' }}>
              <h1 className="card-title"style={{color:'#003467',fontSize:'50px'}}>6</h1>
              <p className="card-text" style={{color:'#000'}}>
                Years Experience
              </p>
            </div>
          </div>
        </div>

        {/* Column 2 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid #003467' }}>
              <h1 className="card-title"style={{color:'#003467',fontSize:'50px'}}>150+</h1>
              <p className="card-text" style={{color:'#000'}}>
                Happy Customers
              </p>
            </div>
          </div>
        </div>

        {/* Column 3 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid #003467' }}>
              <h1 className="card-title" style={{color:'#003467',fontSize:'50px'}}>300+</h1>
              <p className="card-text" style={{color:'#000'}}>
                Projects Completed
              </p>
            </div>
          </div>
        </div>

        {/* Column 4 */}
        <div className="col-lg-3 col-md-6 col-sm-12 mb-4" >
          <div className="card bg-transparent border-0 text-white">
            <div className="card-body" style={{ borderLeft: '2px solid #003467' }}>
              <h1 className="card-title" style={{color:'#003467',fontSize:'50px'}}>10
              </h1>
              <p className="card-text" style={{color:'#000'}}>
                Awards Achievements
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
