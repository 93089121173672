import React from "react";

const CareerBanner = () => {
  return (
    <div style={{ marginTop: '150px' }}>
      <div className="banner-section career">
        <div className="w-layout-blockcontainer base-container w-container">
          <div className="home-3-banner-wrapper">
            <div className="career-text-container">
              <h1 className="careers-heading" style={{fontWeight:'bold',color:'#003467',textAlign:'left'}}>
                Achieve Your Professional Goals with TIERS
              </h1>
              <p className="paragraph-large mobile-center">
                Value-based upscaling is at the heart of TIERS’ work environment. From learning to implementation, our developers and tech experts stay ahead in their professional game.
              </p>
              <a
                href="#"
                target="_blank"
                className="career-primary w-inline-block"
                style={{ textDecoration: 'none' }}
              >
                <div>Career Opportunities</div>
              </a>
            </div>
          </div>
        </div>
        <div className="images-container career">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a27120_CTA%20IMG%204.webp"
              loading="lazy"
              alt="team"
              className="about-us--image top"
              style={{ borderRadius: '20px' }}
            />
          </div>
          {/* <div>
            <img
              src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a2711f_Image%203.webp"
              loading="lazy"
              alt="team"
              className="about-us-header-image bottom"
            />
          </div> */}
        </div>
      </div>

      {/* Responsive Font Size */}
      <style jsx>{`
        .careers-heading {
          font-size: 4rem; /* Default size for large screens */
          font-weight: bold;
          text-align: left;
          color: #003467;
        }

        @media (max-width: 1200px) {
          .careers-heading {
            font-size: 3rem; /* Slightly smaller for medium screens */
          }
        }

        @media (max-width: 768px) {
          .careers-heading {
            font-size: 2.5rem; /* Smaller for tablets */
          }
        }

        @media (max-width: 576px) {
          .careers-heading {
            font-size: 2rem; /* Smallest for mobile phones */
          }
        }
      `}</style>
    </div>
  );
};

export default CareerBanner;
