import React from "react";
import Cookies from "js-cookie";
import Intro from "../Components/PortfolioDetail/Intro";
import Client from "../Components/PortfolioDetail/Client";
import InnovationSolution from "../Components/PortfolioDetail/InnovationSolution";
import Benefits from "../Components/PortfolioDetail/Benefits";
import Features from "../Components/PortfolioDetail/Features";
import Review from "../Components/PortfolioDetail/Review";
import CaseStudies from "../Components/PortfolioDetail/CaseStudies";

const cardData = [
  {
    id: 1,
    title: "Code Delirium",
    imgSrc: "../assets/code.jpg", // Replace with actual image URL
    client: "Code Delirium",
    country: "USA",
    date: "August 2023",
    innovationSolution: {
      revenueGrowth: "15-20%",
      customerSatisfaction: "+95%",
      customerExpansion: "+250",
      projectDiversification: "+90",
    },
    description:
      "Creating this project for a software agency specializing in IT services represents a significant milestone for Code Delirium. It underscores our commitment to delivering tailored solutions that meet the unique needs of our clients. This project not only demonstrates our ability to adapt and innovate within the IT services sector but also highlights our dedication to enhancing the operational efficiency and technological capabilities of our partners. By successfully executing this project, we solidify our reputation as a versatile and reliable development partner capable of handling complex requirements and delivering high-quality results.",
    features: [
      "Custom IT Solutions: Tailored software solutions designed to address the specific needs of the software agency.",
      "Enhanced Operational Efficiency: Tools and systems developed to streamline processes and improve productivity.",
      "Scalable Architecture: A scalable and flexible architecture to accommodate future growth and changes.",
      "Integration Capabilities: Seamless integration with existing systems and technologies.",
      "User-Friendly Interface: Intuitive and user-friendly interfaces to ensure ease of use and accessibility.",
      "Robust Security: Advanced security measures to protect sensitive data and ensure compliance with industry standards.",
      "Performance Optimization: High-performance features optimized for speed and efficiency.",
      "Ongoing Support: Dedicated support and maintenance services to address any issues and ensure smooth operation.",
    ],
    tagdescription:
      "American tech companies are harnessing these tailored IT solutions to drive innovation and enhance operational efficiency, leveraging scalable and secure technologies to stay ahead in a competitive market.",
    economytag: "For boosting the American economy:",
    economy:
      "By integrating these advanced IT solutions, American tech companies are significantly contributing to economic growth, with improved productivity and technological advancement bolstering the U.S. economy and driving substantial economic activity.",
    review:{
      name: "Code Delirium",
      rank: "JCEO",
      text: "Working with TIERS Limited on our Code Delirium project was a transformative experience. Their team's expertise and innovative approach elevated the quality of our development process, delivering results that exceeded our expectations. Their dedication and technical prowess were pivotal in achieving our project goals. We highly recommend TIERS Limited for any ambitious tech endeavor.",
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 2,
    title: "NexTrade",
    imgSrc: "../assets/next.jpg", // Replace with actual image URL
    client: "NexTrade",
    country: "USA",
    date: "Feb 2022",
    innovationSolution: {
      revenueGrowth: "10-12%",
      customerSatisfaction: "+90%",
      customerExpansion: "+200",
      projectDiversification: "+85",
    },
    description:
      "NexTrade represents a groundbreaking advancement in facilitating global trade through innovative technology. By leveraging cutting-edge solutions, NexTrade aims to streamline and enhance international trade processes, making them more efficient and accessible. This project embodies our vision of breaking down barriers and fostering seamless global commerce, thereby creating new opportunities and driving growth in the global market.",
    features: [
      "Global Trade Facilitation: Streamlined processes for international trade.",
      "Technology Integration: Advanced technological solutions to support trade activities.",
      "Enhanced Efficiency: Tools and systems designed to improve operational efficiency.",
      "User-Friendly Platform: Intuitive interface for ease of use.",
      "Real-Time Data: Access to up-to-date information and analytics.",
      "Scalable Solutions: Adaptable to different scales and needs of trade operations.",
      "Secure Transactions: Robust security features to protect trade data and transactions.",
    ],
    tagdescription: `American tech companies are adopting NexTrade's advanced solutions to revolutionize global trade processes, leveraging cutting-edge technology to streamline operations and enhance efficiency.`,
    economytag: `For boosting the American economy:`,
    economy: `By integrating NexTrade's innovative platform, American businesses are driving global commerce and economic growth, with improved trade efficiency and real-time data access contributing to a robust and expanding economy.`,
    review:{
      name: `NexTrade`,
      rank: `VP Technology`,
      text: `Choosing TIERS Limited for the NexTrade project proved to be an excellent decision. Their team demonstrated exceptional skill and creativity, turning complex requirements into an efficient and robust solution. Their dedication and attention to detail ensured the project's success. TIERS Limited is a partner we trust and recommend without reservation.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 3,
    title: "UNITEN",
    imgSrc: "../assets/uniten.jpg", // Replace with actual image URL
    client: "UNITEN",
    country: "Malaysia",
    date: "Nov 2023",
    innovationSolution: {
      revenueGrowth: "10-12%",
      customerSatisfaction: "+95%",
      customerExpansion: "+250",
      projectDiversification: "+95",
    },
    description:
      "Uniten, a Malaysian university, is a key project that underscores our role in advancing educational technology. By collaborating with Uniten, we aim to enhance their digital infrastructure and academic systems, supporting their goal of delivering exceptional education and research opportunities. This project highlights our dedication to fostering innovation in the education sector and creating solutions that empower students and faculty alike.",
    features: [
      "Academic management systems for streamlined processes.",
      "Digital learning platforms to support online and hybrid education.",
      "Integrated campus management solutions.",
      "User-friendly student and faculty portals.",
      "Advanced data analytics for improved institutional decision-making.",
      "Robust security features to protect data and ensure compliance.",
      "Scalable solutions to adapt to the university’s growing needs.",
    ],
    tagdescription: `Malaysian tech companies are utilizing our advanced solutions to upgrade Malaysia University's digital infrastructure, streamlining academic processes and enhancing the overall educational experience.`,
    economytag: `For boosting the Malaysian economy:`,
    economy: `By implementing state-of-the-art educational technology at Malaysia University, Malaysian tech companies are driving advancements in the education sector, which fosters innovation and contributes to the nation’s economic growth and development.`,
    review:{
      name: `UNITEN`,
      rank: `CTO`,
      text: `TIERS Limited demonstrated exceptional skill and dedication on the UNITEN project. Their ability to deliver sophisticated solutions with precision and creativity was instrumental in our success. Their team’s professionalism and commitment to exceeding expectations were evident throughout the project. We confidently recommend TIERS Limited for their superior expertise and reliable service.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 4,
    title: "ResoSyncer",
    imgSrc: "../assets/reso.jpg", // Replace with actual image URL
    client: "ResoSyncer",
    country: "USA",
    date: "May 2024",
    innovationSolution: {
      revenueGrowth: "15-17%",
      customerSatisfaction: "+90%",
      customerExpansion: "+300",
      projectDiversification: "+95",
    },
    description:
      "ResoSyncer, our innovative Resource Manager, is designed to transform how organizations manage and optimize their resources. This project reflects our commitment to delivering powerful solutions that streamline resource allocation, enhance operational efficiency, and provide valuable insights for better decision-making. ResoSyncer embodies our dedication to creating tools that drive productivity and support dynamic resource management strategies.",
    features: [
      "Streamlined resource allocation and tracking.",
      "Enhanced operational efficiency through automation.",
      "Real-time analytics and reporting for informed decision-making.",
      "User-friendly interface for easy management and oversight.",
      "Integration capabilities with existing systems.",
      "Scalable to accommodate various organizational needs.",
      "Robust security features to protect resource data and ensure compliance.",
    ],
    tagdescription: `American tech companies are adopting ResoSyncer to revolutionize resource management, leveraging streamlined allocation, real-time analytics, and automation to boost operational efficiency and decision-making.`,
    economytag: `For boosting the American economy:`,
    economy: `By integrating ResoSyncer’s innovative resource management solutions, American businesses are enhancing productivity and operational efficiency, which contributes significantly to economic growth and competitive advantage.`,
    review:{
      name: `Founder and CEO`,
      rank: `ResoSyncer`,
      text: `Partnering with TIERS Limited on the ResoSyncer project has been a remarkable experience. Their team's innovative approach and technical excellence significantly enhanced our resource management platform. TIERS Limited's dedication and ability to tackle complex challenges were key to the project's success. We highly value their expertise and recommend them for any high-impact project.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 5,
    title: "Neuronetix",
    imgSrc: "../assets/neuro.jpg", // Replace with actual image URL
    client: "Neuronetix",
    country: "Switzerland",
    date: "Dec 2023",
    innovationSolution: {
      revenueGrowth: "12-15%",
      customerSatisfaction: "+85%",
      customerExpansion: "+150",
      projectDiversification: "+85",
    },
    description:
      "Neronetix, a Swiss IT company, signifies a major achievement in our portfolio, showcasing our ability to deliver cutting-edge technology solutions in the highly competitive IT sector. This project emphasizes our expertise in crafting advanced, reliable, and scalable solutions that meet the rigorous demands of international clients. By partnering with Neronetix, we demonstrate our capability to support sophisticated IT operations and contribute to the growth and success of leading technology firms.",
    features: [
      "Advanced technology solutions tailored to the IT sector.",
      "Scalable and reliable systems designed for high-demand environments.",
      "Integration with existing IT infrastructure for seamless operations.",
      "Real-time performance monitoring and analytics.",
      "User-friendly interfaces for efficient management and control.",
      "Enhanced security measures to protect sensitive IT data.",
      "Customizable solutions to meet specific client requirements.",
    ],
    tagdescription: `Swiss IT companies are utilizing Neronetix’s advanced solutions to enhance their technology infrastructure, benefiting from scalable, reliable systems and real-time analytics to support high-demand IT operations.`,
    economytag: `For boosting the Swiss economy:`,
    economy: `By deploying Neronetix’s cutting-edge technology, Swiss IT companies are driving growth and innovation within the tech sector, contributing to Switzerland’s reputation as a leader in advanced IT solutions and boosting the national economy.`,
    review:{
      name: `Neuronetrix`,
      rank: `Co-Founder`,
      text: `Collaborating with TIERS Limited on the Neuronetix project was a highly rewarding experience. Their team’s advanced technical skills and innovative approach were pivotal in developing a cutting-edge solution that met our ambitious goals. TIERS Limited’s professionalism and commitment to excellence made them an invaluable partner. We highly recommend their services for any complex and forward-thinking projects.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 6,
    title: "Gringo",
    imgSrc: "../assets/gringo.jpg", // Replace with actual image URL
    client: "Gringo",
    country: "Brazil",
    date: "Sep 2023",
    innovationSolution: {
      revenueGrowth: "15-20%",
      customerSatisfaction: "+95%",
      customerExpansion: "+300",
      projectDiversification: "+95",
    },
    description:
      "GringoTeachesU is a dynamic platform dedicated to helping students master English through engaging and interactive learning experiences. This project highlights our commitment to enhancing educational opportunities by providing a user-friendly, accessible tool that supports language acquisition. By developing GringoTeachesU, we aim to empower students with the skills they need to succeed in English-speaking environments and advance their personal and professional goals.",
    features: [
      "Interactive lessons and exercises for comprehensive language learning.",
      "Engaging content designed to enhance student motivation and participation.",
      "Personalized learning paths to cater to individual proficiency levels.",
      "Real-time feedback and progress tracking to support continuous improvement.",
      "User-friendly interface that simplifies the learning process.",
      "Integration of multimedia resources for diverse learning styles.",
      "Access to a supportive community for practice and collaboration.",
    ],
    tagdescription: `Brazilian education companies are leveraging GringoTeachesU to provide students with interactive, personalized English learning experiences, enhancing language acquisition through engaging content and real-time feedback.`,
    economytag: `For boosting the Brazilian economy:`,
    economy: `By adopting GringoTeachesU, Brazilian educational platforms are fostering language skills development, which supports personal and professional growth, and contributes to economic advancement by enhancing the global competitiveness of Brazilian students.`,
    review:{
      name: `Gringo`,
      rank: `Founder`,
      text: `Collaborating with TIERS Limited on the Gringo project has been a remarkable experience. Their exceptional technical skills and innovative problem-solving approach significantly enhanced our project’s success. Their commitment to excellence and attention to detail have been invaluable. We highly endorse TIERS Limited for any complex software development needs.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 7,
    title: "Swissmine",
    imgSrc: "../assets/swissmine.jpg", // Replace with actual image URL
    client: "Swissmine",
    country: "Switzerland",
    date: "Feb 2024",
    innovationSolution: {
      revenueGrowth: "8-10%",
      customerSatisfaction: "+85%",
      customerExpansion: "+150",
      projectDiversification: "+80",
    },
    description:
      "Swissmine is a cutting-edge platform designed to simplify the complexities of cryptocurrency mining. With Swissmine, users can effortlessly leverage the benefits of a professional mining host, making the process accessible and efficient. Our focus is on delivering a streamlined experience that allows users to start hosting their mining operations with ease, while enjoying the advantages of advanced technology and expert support.",
    features: [
      "Simplified setup for cryptocurrency mining operations.",
      "Professional mining host services to optimize performance.",
      "User-friendly interface for easy management and monitoring.",
      "Advanced technology to enhance mining efficiency.",
      "Access to expert support and maintenance.",
      "Scalable hosting options to meet varying needs.",
      "Secure infrastructure to protect mining activities and data.",
    ],
    tagdescription: `Swiss tech companies are utilizing Swissmine to streamline cryptocurrency mining operations, benefiting from its user-friendly interface, advanced technology, and expert support to optimize performance and efficiency.`,
    economytag: `For boosting the Swiss economy:`,
    economy: `By integrating Swissmine’s sophisticated mining solutions, Swiss tech companies are advancing the cryptocurrency sector, driving innovation and economic growth through enhanced mining efficiency and secure operations.`,
    review:{
      name: `Swissmine`,
      rank: `Head of Technology`,
      text: `TIERS Limited's expertise and dedication were instrumental in the success of the SwissMine project. Their team's innovative solutions and meticulous attention to detail exceeded our expectations and delivered outstanding results. TIERS Limited's commitment to excellence and professionalism makes them a trusted partner for any technology-driven project. We wholeheartedly recommend their services.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 8,
    title: "Scholar Suites",
    imgSrc: "../assets/scholor.jpg", // Replace with actual image URL
    client: "Scholar Suites",
    country: "USA",
    date: "April 2024",
    innovationSolution: {
      revenueGrowth: "15-20%",
      customerSatisfaction: "+95%",
      customerExpansion: "+270",
      projectDiversification: "+95",
    },
    description:
      "Scholarsuites is an innovative platform designed to simplify the process of finding and securing hostel accommodations for students. This project reflects our commitment to enhancing the student experience by providing a streamlined, user-friendly solution for locating and booking suitable housing. Scholarsuites aims to make the search for comfortable, affordable, and convenient living options as effortless as possible, supporting students in their academic journey.",
    features: [
      "Easy-to-use platform for searching and booking hostels.",
      "Comprehensive listings with detailed information about accommodations.",
      "User reviews and ratings to help choose the best option.",
      "Filtering options to match specific needs and preferences.",
      "Secure booking and payment processes.",
      "Support for finding hostels in various locations.",
      "Access to customer service for assistance and inquiries.",
    ],
    tagdescription: `American tech companies are adopting Scholarsuites to transform the hostel booking process for students, offering a user-friendly platform with comprehensive listings and secure transactions to simplify finding and securing accommodations.`,
    economytag: `For boosting the American economy:`,
    economy: `By utilizing Scholarsuites, American businesses are enhancing the student housing market, driving growth and efficiency in accommodation services, and contributing to the broader economic impact through improved student experiences and streamlined operations.`,
    review:{
      name: `Scholar Suites`,
      rank: `Founder & CEO`,
      text: `Working with TIERS Limited on the Scholar Suites project was a true pleasure. Their innovative approach and deep technical expertise played a crucial role in shaping a solution that perfectly met our needs. Their team’s commitment to excellence and ability to deliver on time was impressive. We highly recommend TIERS Limited for their outstanding performance and dedication`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
  {
    id: 9,
    title: "NeaterNotes",
    imgSrc: "../assets/notes.jpg", // Replace with actual image URL
    client: "NeaterNotes",
    country: "USA",
    date: "May 2023",
    innovationSolution: {
      revenueGrowth: "15-16%",
      customerSatisfaction: "+95%",
      customerExpansion: "+350",
      projectDiversification: "+90",
    },
    description:
      "Neaternotes is an advanced platform that leverages AI technology to revolutionize how notes are scanned, managed, and organized. Designed to streamline the process of handling handwritten and digital notes, Neaternotes offers an intelligent solution that enhances productivity and organization. This project embodies our commitment to integrating cutting-edge technology to improve everyday tasks and support efficient information management.",
    features: [
      "AI-powered scanning for accurate digitization of handwritten notes.",
      "Advanced text recognition and extraction for easy search and retrieval.",
      "Intelligent organization and categorization of notes.",
      "User-friendly interface for seamless note management.",
      "Integration with cloud storage for secure access and backup.",
      "Real-time syncing across devices for up-to-date information.",
      "Tools for annotating and editing notes for enhanced usability.",
    ],
    tagdescription: `American tech companies are leveraging Neaternotes to enhance productivity and organization, utilizing AI-powered scanning and intelligent categorization to streamline the management of both handwritten and digital notes.`,
    economytag: `For boosting the American economy:`,
    economy: `By implementing Neaternotes, American businesses are advancing information management technology, improving efficiency in note-taking and organization, and contributing to economic growth through increased productivity and innovative solutions.`,
    review:{
      name: `Neaternotes`,
      rank: `Product Manager`,
      text: `TIERS Limited’s contribution to the Neaternotes project was exceptional. Their expertise in delivering a user-friendly and efficient solution greatly improved our platform. Their innovative approach and thorough attention to detail were crucial in achieving our objectives. We are very pleased with their performance and would highly recommend TIERS Limited for any technology-driven initiative.`,
      rating: 5,
      avatar: "../assets/ashkan.jpg",
    }
  },
];

const featuresData = [
  {
    logoUrl: "https://cdn-icons-png.flaticon.com/128/7442/7442972.png",
    title: "Efficient Technical Support",
    description:
      "Our efficient technical support team is dedicated to being your trusted IT partner.",
  },
  {
    logoUrl: "https://cdn-icons-png.flaticon.com/128/17218/17218187.png",
    title: "Enhanced Productivity",
    description:
      "In the fast-paced world of business, enhancing productivity is a constant challenge.",
  },
  {
    logoUrl: "https://cdn-icons-png.flaticon.com/128/2801/2801923.png",
    title: "Minimized Incidents",
    description:
      "Trust us for proactive monitoring, rapid incident response, and scalable solutions.",
  },
];

const features = [
  "The employee checks their leave balances",
  "The employee drops an email to their manager",
  "The manager looks through the work calendar",
  "who then adjusts the leave balances accordingly",
];

const singleReview = {
  name: "Ashkan (Ash)",
  rank: "CEO at Code Delirium",
  text: "TIERS Limited's innovative approach and expertise have made them an indispensable partner, transforming our projects into successful realities.",
  rating: 5,
  avatar: "../assets/ashkan.jpg",
};

export default function PortfolioDetail() {
  // Get the cardId from cookies
  const cardId = Cookies.get("cardid");

  // Find the card data based on the cardId
  const card = cardData.find((item) => item.id === parseInt(cardId, 10));

  // Check if card is found
  if (!card) {
    return <div style={{ marginTop: "200px" }}>Card not found</div>;
  }

  return (
    <div style={{ marginTop: "150px" }}>
      <Intro
        title={card.title}
        imageSrc={card.imgSrc}
        description={card.tagdescription}
      />
      <Client
        imageSrc={`https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem4.png&w=3840&q=75`}
        title={card.economytag}
        description={card.economy}
        clientName={card.client}
        country={card.country}
        date={card.date}
      />
      <InnovationSolution
        subtitle={`Services Used`}
        title={`Innovation Solutions`}
        innovationSolution={card.innovationSolution}
      />
      <Benefits
        features={featuresData}
        title="The Benefits They Have Gained From Using It"
        imageSrc="https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fcomponent%2Fbg-case-detail-benefit.png&w=3840&q=75"
      />
      <Features
        title="What The Project Means For us"
        description={card.description}
        title1="Features"
        description1="Initially, most companies opt for a manual process that involves a lot of busywork for the HR department:"
        listItems={card.features}
      />
      <Review review={card.review} />
      <CaseStudies />
    </div>
  );
}
