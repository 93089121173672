import React,{useState, useEffect} from "react";

const OurJourney = () => {
  const journeyData = [
    {
      date: "02/2018",
      title: "The Early Days",
      description:
        "In the early years, we were a small team with a big vision. We started with a handful of passionate individuals determined to make a difference.",
      imageUrl:
        "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem16.png&w=3840&q=75",
    },
    {
      date: "01/2019",
      title: "Growth and Expansion",
      description:
        "We expanded our operations, both in terms of scope and geography, to better serve the evolving needs of our clients.",
      imageUrl:
        "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem11.png&w=3840&q=75",
    },
    {
      date: "04/2020",
      title: "Adapting to Change",
      description:
        "We embraced emerging technologies, implemented sustainable practices, and fostered a culture of innovation that has allowed us to stay ahead cuve.",
      imageUrl:
        "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem14.png&w=3840&q=75",
    },
    {
      date: "06/2021",
      title: "Our Team",
      description:
        "They are the heart of our organization, and their unwavering commitment to excellence has been the driving force behind our growth.",
      imageUrl:
        "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem15.png&w=3840&q=75",
    },
    {
      date: "05/2022",
      title: "Client-Centric Approach",
      description:
        "We have always placed their needs and satisfaction at the center of everything we do. Their trust and loyalty have been instrumental in our journey.",
      imageUrl:
        "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem13.png&w=3840&q=75",
    },
    {
      date: "08/2024",
      title: "Looking Ahead",
      description:
        "We remain dedicated to pushing boundaries, delivering unmatched IT Solution, and continuing to be a partner in the success of our clients.",
      imageUrl:
        "https://bytewave-next.vercel.app/_next/image?url=%2Fimages%2Fblog%2Fitem12.png&w=3840&q=75",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    // console.log(isMobile)

    const intervalId = setInterval(checkScreenSize, 1000);

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, []);

  return (
    <div className="container py-5">
      <h1 className="heading"  style={{color:'#003467', textAlign:'center'}}>Our Journey Through Time</h1>
      <p className="text-center mb-5" style={{fontSize:'20px'}}>
        Tracing the Path of Our Legacy and Growth
      </p>
      {journeyData.map((item, index) => (
        <div>
          {index % 2 === 0 ? (
            <div className="row journey-row1 align-items-center" key={index}>
              <div
                className="col-md-6 text-md-right"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ textAlign: 'right', width: "80%",marginTop:'10px' }}>
                  <h5 style={{ textAlign:  isMobile ? 'left' : "right" }}>{item.date}</h5>
                  <h4 style={{ textAlign:  isMobile ? 'left' : "right" }}>{item.title}</h4>
                  <p style={{ textAlign:  isMobile ? 'left' : "right" }}>{item.description}</p>
                </div>
              </div>

              <div
                className="col-md-6"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className="journey-image"
                />
              </div>
            </div>
          ) : (
            <div className="row journey-row align-items-center" key={index}>
              <div
                className="col-md-6"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={item.imageUrl}
                  alt={item.title}
                  className="journey-image"
                />
              </div>
              <div
                className="col-md-6 text-md-left"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "80%", marginLeft: "10%",marginTop:'10px'  }}>
                  <h5>{item.date}</h5>
                  <h4>{item.title}</h4>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <style jsx>{`
        .journey-row {
          margin-bottom: 3rem;
        }
        .journey-row1 {
          margin-bottom: 3rem;
        }
        .journey-image {
          border-radius: 20px;
          width: 80%;
          height: auto;
        }
        @media (max-width: 767.98px) {
          .journey-row1 {
            flex-direction: column-reverse;
          }
          .text-md-right {
            text-align: left !important;
          }
          .text-md-left {
            text-align: left !important;
          }
        }
      `}</style>
    </div>
  );
};

export default OurJourney;
