import React, { useEffect, useRef } from "react";

const OnboardingProcess = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const processItems = containerRef.current.querySelectorAll(".process-item");

    const observerOptions = {
      root: null, // relative to the viewport
      rootMargin: "0px",
      threshold: 0.1, // trigger when 10% of the component is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          processItems.forEach((item, index) => {
            setTimeout(() => {
              item.style.animation = `moveUp 1s ease forwards`;
              item.style.animationDelay = `${index * 0.5}s`;
            }, index * 500); // Adjust the delay as needed
          });
          observer.disconnect(); // Stop observing after animation starts
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect(); // Clean up observer on component unmount
    };
  }, []);

  return (
    <div ref={containerRef} className="section bg-working-process">
      <div className="base-container w-container">
        <h2 class="careers-heading" style={{ marginTop: "50px" }}>
          Get To Know Our Onboarding Process
        </h2>
        <div className="working-process-wrap">
          <div className="section-title-wrapp-working-process">
            <h2 class="careers-heading1">
              We Make Hiring Simple and Efficient
            </h2>
            <p className="paragraph-large white-text">
              While maintaining transparency at the core, we look for
              change-makers like you!
            </p>
          </div>
          <div className="w-layout-grid flex-stages">
            <div className="process-item right-margin gap-80">
              <div className="stage">
                <div className="process-item-wrap">
                  <h2>
                    <span className="text-white">01</span>
                  </h2>
                  <div className="process-divider"></div>
                </div>
                <h5 className="text-white">
                  <strong>Apply</strong>
                </h5>
                <p className="stage-description">
                  In this first step, you apply for the current opportunity
                  available by attaching an attention-grabbing resume,
                  portfolio, and cover letter.
                </p>
              </div>
            </div>
            <div className="process-item gap-80">
              <div className="stage">
                <div className="process-item-wrap">
                  <h2>
                    <span className="text-white">02</span>
                  </h2>
                </div>
                <h5 className="text-white text-center">
                  <strong>Review</strong>
                </h5>
                <p className="stage-description">
                  Now, the information you shared goes for a thorough review by
                  our experts to ensure none of your achievements are
                  overlooked.
                </p>
              </div>
            </div>
            <div className="process-item right-margin gap-80">
              <div className="stage">
                <h2>
                  <span className="text-white">03</span>
                </h2>
                <h5 className="text-white text-center">
                  <strong>Interview</strong>
                </h5>
                <p className="stage-description">
                  Once you are shortlisted, our talent acquisition specialists
                  get back to you right away to move forward with the process.
                </p>
              </div>
            </div>
            <div className="process-item gap-80">
              <div className="stage">
                <h2>
                  <span className="text-white">04</span>
                </h2>
                <h5 className="text-white text-center">
                  <strong>Onboarding</strong>
                </h5>
                <p className="stage-description">
                  From introducing you to TIERS Limited vibrant culture to helping
                  you settle smoothly, our onboarding team tags along till the
                  end.
                </p>
              </div>
            </div>
            <div className="gradient-services">
              <div className="home-3-company-wrap"></div>
            </div>
          </div>
        </div>
      </div>
      <img
        src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a27110_logo%20home.svg"
        loading="lazy"
        alt="bg logo"
        className="logo-bg-home-process right"
      />
      <img
        src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a2710f_Logo%20Bg.svg"
        loading="lazy"
        alt="bg logo"
        className="logo-bg-home-process"
      />
      <style jsx>{`
        .careers-heading {
          font-size: 3rem; /* Default size for large screens */
          font-weight: bold;
          text-align: center;
          color: #fff;
        }

        .careers-heading1 {
          font-size: 3rem; /* Default size for large screens */
          font-weight: bold;
          text-align: left;
          color: #fff;
        }

        @media (max-width: 1200px) {
          .careers-heading {
            font-size: 2.5rem; /* Slightly smaller for medium screens */
          }
          .careers-heading1 {
            font-size: 2.5rem; /* Slightly smaller for medium screens */
          }
        }

        @media (max-width: 768px) {
          .careers-heading {
            font-size: 2rem; /* Smaller for tablets */
          }
          .careers-heading1 {
            font-size: 2rem; /* Smaller for tablets */
          }
        }

        @media (max-width: 576px) {
          .careers-heading {
            font-size: 1.5srem; /* Smallest for mobile phones */
          }
          .careers-heading1 {
            font-size: 1.5srem; /* Smallest for mobile phones */
          }
        }
      `}</style>
    </div>
  );
};

export default OnboardingProcess;
