import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Review = ({ review }) => {
  return (
    <div className="container my-5 review-section-container">
      <div style={{ padding: "20px",background: "linear-gradient(to right, #f4f6fa, #f9f7ef)",borderRadius:'30px' }}>
          <div
            className="row align-items-center"
            style={{ padding: "0px"}}
          >
            {/* Left Column - Image */}
            

            {/* Right Column - Text */}
            <div className="col-md-12" style={{ position: "relative" }}>
              <div
                style={{
                  // backgroundColor: "#f7f7f7",
                  padding: "30px 20px",
                  borderRadius: "8px",
                }}
              >
                <div className="rating" style={{marginBottom:'30px'}}>
                  {Array(review.rating)
                    .fill()
                    .map((_, i) => (
                      <span key={i} style={{ color: "#0398f8" }}>
                        ⭐
                      </span>
                    ))}
                </div>
                <h4 style={{ color: "#000" ,textAlign:'justify'}}>
                  {review.text}
                </h4>
                <p
                  className="reviewer-name"
                  style={{
                    fontWeight: "bold",
                    color: "#000",
                    margin: "0px",
                  }}
                >
                  {review.name}
                </p>
                <p
                  className="reviewer-rank"
                  style={{ color: "#666", margin: "0px" }}
                >
                  {review.rank}
                </p>
              </div>
              {/* <img
                src="../assets/review.png"
                alt=""
                style={{
                  position: "absolute",
                  bottom: "30px", // Adjust the distance from the bottom
                  right: "30px", // Adjust the distance from the right
                  width: "50px", // Adjust the size of the image
                  height: "auto",
                }}
              /> */}
            </div>
            {/* <div className="col-md-6">
              <img
                src={review.avatar}
                alt={review.name}
                style={{
                  width: "80%",
                  height: "auto",
                  borderRadius: "20px",
                  margin: "10%",
                }}
              />
            </div> */}
          </div>
      </div>

      <style>
        {`
          .review-card {
            background-color: #f8f9fa;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            margin: 0 10px;
          }
        `}
      </style>
    </div>
  );
};

export default Review;
