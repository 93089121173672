import React, { useState, useRef } from "react";
import Slider from "react-slick";
// import Carousel from "react-bootstrap/Carousel";
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import FullScreenImageViewer from "./fullscreen";

const caseStudies = [
  {
    id: 1,
    imgSrc: [
      "../assets/Keynote Speaking/ITEC-23/1.jpg",
      "../assets/Keynote Speaking/ITEC-23/2.jpg",
      "../assets/Keynote Speaking/ITEC-23/3.jpg",
      "../assets/Keynote Speaking/ITEC-23/4.jpg",
      "../assets/Keynote Speaking/ITEC-23/5.jpg",
    ],
    title: "ITEC-23",
    keywords: ["Networking", "Awards Ceremony"],
  },
  {
    id: 2,
    imgSrc: [
      "../assets/Keynote Speaking/Notion Learn and Lunch/1.jpg",
      "../assets/Keynote Speaking/Notion Learn and Lunch/2.jpg",
      "../assets/Keynote Speaking/Notion Learn and Lunch/3.jpg",
      "../assets/Keynote Speaking/Notion Learn and Lunch/4.jpg",
      "../assets/Keynote Speaking/Notion Learn and Lunch/5.jpg",
    ],
    title: "Notion Learn and Lunch",
    keywords: ["Technology Conference", "Networking"],
  },
  {
    id: 3,
    imgSrc: [
      "../assets/Keynote Speaking/Future Fest 2024/1.jpg",
      "../assets/Keynote Speaking/Future Fest 2024/2.jpg",
      "../assets/Keynote Speaking/Future Fest 2024/3.jpg",
      "../assets/Keynote Speaking/Future Fest 2024/4.jpg",
      "../assets/Keynote Speaking/Future Fest 2024/5.jpg",
    ],
    title: "Future Fest 2024",
    keywords: ["Tech Expo", "Innovation"],
  },
  {
    id: 4,
    imgSrc: [
      "../assets/Keynote Speaking/Itnl freelancers day 2023/1.jpg",
      "../assets/Keynote Speaking/Itnl freelancers day 2023/2.jpg",
      "../assets/Keynote Speaking/Itnl freelancers day 2023/3.jpg",
      "../assets/Keynote Speaking/Itnl freelancers day 2023/4.jpg",
      "../assets/Keynote Speaking/Itnl freelancers day 2023/5.jpg",
    ],
    title: "Intl. Freelancers Day 2023",
    keywords: ["Freelancers", "Networking"],
  },
  {
    id: 5,
    imgSrc: [
      "../assets/Keynote Speaking/Lahore Meetup/1.jpg",
      "../assets/Keynote Speaking/Lahore Meetup/2.jpg",
      "../assets/Keynote Speaking/Lahore Meetup/3.jpg",
      "../assets/Keynote Speaking/Lahore Meetup/4.jpg",
      "../assets/Keynote Speaking/Lahore Meetup/5.jpg",
    ],
    title: "Lahore Meetup 2024",
    keywords: ["Finance", "Networking"],
  },
];

const KeyNoteSpeaking = () => {
  const [selectedStudyId, setSelectedStudyId] = useState(null);
  const [fullScreenView, setFullScreenView] = useState({ visible: false, index: 0 });
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  };

  const handleCardClick = (studyId, index) => {
    setSelectedStudyId(studyId);
    setFullScreenView({ visible: true, index: index });
  };

  const closeFullScreenView = () => {
    setFullScreenView({ visible: false, index: 0 });
  };

  return (
    <div style={{ background: "#f7f7f7" }}>

      <div
        className="container"
        style={{ overflow: "hidden", background: "#f7f7f7" }}
      >
        <h1
          className="heading"
          style={{ textAlign: "center", margin: "50px 0px" }}
        >
          Keynote Speaking
        </h1>

        <Slider ref={sliderRef} {...settings} style={{ maxHeight: "550px" }}>
          {caseStudies.map((study, index) => (
            <div
              key={study.id}
              className="case-study-card-wrapper"
            >
              <div
                className="case-study-card"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  overflow: "hidden",
                  cursor: "pointer",
                  transform:
                    selectedStudyId === study.id ? "scale(1.15)" : "scale(1)",
                  transition: "transform 0.3s ease",
                  boxShadow:
                    selectedStudyId === study.id
                      ? "0px 4px 15px rgba(0, 0, 0, 0.2)"
                      : "none",
                  height: "auto",
                  marginTop: "50px",
                }}
                onClick={() => handleCardClick(study.id, index)}
              >
                <img
                  src={study.imgSrc[0]}
                  alt={study.title}
                  className="d-block w-100"
                  style={{
                    height: "250px",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
                <div className="case-study-content" style={{ padding: "20px" }}>
                  <div className="case-study-keywords">
                    {study.keywords.map((keyword, i) => (
                      <span
                        key={i}
                        className="case-study-keyword badge"
                        style={{ color: "#0398f8" }}
                      >
                        {keyword}
                      </span>
                    ))}
                  </div>
                  <h5
                    className="case-study-title"
                    style={{ fontSize: "1.25rem", marginBottom: "10px" }}
                  >
                    {study.title}
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {fullScreenView.visible && (
        <FullScreenImageViewer
          images={caseStudies[fullScreenView.index].imgSrc}
          currentIndex={0}
          onClose={closeFullScreenView}
        />
      )}
    </div>
  );
};

export default KeyNoteSpeaking;