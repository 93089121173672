import React from "react";
import BannerSection from "../Components/About/BannerSection";
import Vision from "../Components/About/Vision";
import Analytics from "../Components/About/Analytics";
import FinancialPotential from "../Components/About/FinancialPotential";
import LogoSection from '../Components/Home/LogoSection'
import OurJourney from "../Components/About/OurJourney";
import JourneyThroughTime from "../Components/About/JourneyThroughTime";
import Team from "../Components/About/Team";
import Testimonial from "../Components/About/Testimonial";
import ContactUs from '../Components/Home/ContactUs'

export default function About() {
  return (
    <div>
      <BannerSection />
      <LogoSection/>
      <Vision />
      <Analytics/>
      <FinancialPotential/>
      <OurJourney/>
      <JourneyThroughTime/>
      <Team/>
      <Testimonial/>
      <ContactUs/>
    </div>
  );
}
