import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const industries = [
    {
      id: 'finance-banking',
      title: 'Finance & Banking',
      icon: '💰', // Placeholder icon, replace with actual icon if needed
      description: 'Offering tailored financial solutions to optimize banking operations, enhance customer experiences, and drive growth.',
    },
    {
      id: 'healthcare-pharmaceuticals',
      title: 'Healthcare & Pharmaceuticals',
      icon: '💊',
      description: 'Providing cutting-edge technology solutions for healthcare and pharmaceuticals, ensuring improved patient care and efficient operations.',
    },
    {
      id: 'real-estate',
      title: 'Real Estate',
      icon: '🏠',
      description: 'Delivering innovative real estate technology to streamline property management, transactions, and customer engagement.',
    },
    {
      id: 'education-training',
      title: 'Education & Training',
      icon: '📚',
      description: 'Empowering education and training with interactive e-learning platforms and tools for better knowledge dissemination and learner engagement.',
    },
    {
      id: 'ecommerce-retail',
      title: 'E-commerce & Retail',
      icon: '🛒',
      description: 'Enhancing the e-commerce and retail experience with robust platforms, streamlined operations, and personalized customer interactions.',
    },
    {
      id: 'information-technology-telecommunications',
      title: 'Information Technology & Telecommunications',
      icon: '📡',
      description: 'Driving innovation in IT and telecommunications with advanced solutions that connect businesses and people globally.',
    },
    {
      id: 'transportation-logistics',
      title: 'Transportation & Logistics',
      icon: '🚚',
      description: 'Optimizing transportation and logistics operations with technology that enhances efficiency, tracking, and delivery performance.',
    },
    {
      id: 'manufacturing-industry',
      title: 'Manufacturing & Industry 4.0',
      icon: '🏭',
      description: 'Revolutionizing manufacturing with Industry 4.0 solutions, improving production processes, and ensuring sustainable practices.',
    },
    {
      id: 'energy-environment',
      title: 'Energy & Environment',
      icon: '⚡',
      description: 'Offering sustainable energy and environmental solutions that promote efficiency and environmental stewardship.',
    },
    {
      id: 'entertainment-media',
      title: 'Entertainment and Media',
      icon: '🎥',
      description: 'Transforming entertainment and media with innovative solutions that enhance content creation, distribution, and audience engagement.',
    },
  ];

  
const Industries = () => {
  return (
    <div style={{background:'#fff'}}>   
    <div className="container py-5" style={{background:'#fff'}}>
    <div className="row" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
    <div className="col-lg-8 col-md-6 col-sm-12 mb-4">
        <h1 className="heading" style={{textAlign:'center',margin:'50px 0px'}}>Solve technology problems in every industry that customers need.</h1>
    </div>
    </div>
      <div className="row">

        {industries.map((industry) => (
          <div key={industry.id} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="industry-card">
              <div className="industry-card-inner">
                <div className="industry-card-front">
                  <div className="icon" style={{ fontSize: '50px' }}>{industry.icon}</div>
                  <h5 className="industry-title">{industry.title}</h5>
                </div>
                <div className="industry-card-back">
                <h5 className="industry-title" style={{color:'#003467'}}>{industry.title}</h5>
                  <p className="industry-description">{industry.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Industries;
