import React from "react";

const Features = ({ headingText, points, imageUrl }) => (
  <div className="web-dev-intro container my-5">
    <div className="row align-items-center">
      {/* Column 1: Text and Points */}
      <div className="col-md-6 d-flex flex-column justify-content-center">
        <h2 className="heading" style={{ fontWeight: "500" }}>
          {headingText}
        </h2>
        {/* <ul className="points-list" style={{ listStyle: 'none', paddingLeft: '0', color: '#000' }}> */}
        {points.map((point, index) => (
          <li
            key={index}
            className="point-item"
            style={{ display: "flex", alignItems: "center", color: "#000" }}
          >
            <span
              className="tick-icon"
              style={{ color: "#0398f8", marginRight: "10px" }}
            >
              ✔
            </span>
            {point}
          </li>
        ))}
        {/* </ul> */}
      </div>

      {/* Column 2: Image */}
      <div className="col-md-6 d-flex justify-content-center">
        <img
          src={imageUrl}
          alt="Web Development"
          className="web-dev-image"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            borderRadius: "8px",
          }}
        />
      </div>
    </div>

    <style>
      {`
       .point-item {
            font-size: 1.4rem;
            margin-top:15px;
            font-weight:normal
          }
        @media (max-width: 768px) {
          .heading {
            font-size: 1.5rem;
          }

          .point-item {
            font-size: 1rem;
          }
        }
      `}
    </style>
  </div>
);

export default Features;
