import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaEnvelope,
  FaPaperPlane,
  FaYoutube,
} from "react-icons/fa";
import Cookies from 'js-cookie'
import { Link } from "react-router-dom";

export default function Footer() {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email submitted:", email);
    setEmail("");
  };

  return (
    <>
      <div
        className="footer"
        style={{
          backgroundColor: "#003467",
          color: "white",
          padding: "40px 0",
        }}
      >
        <div className="container">
          <div className="row" style={{ backgroundColor: "#003467" }}>
            <div className="col-md-3 mb-4">
              <div className="footer-logo-wrapper">
                <a href="./index.html" className="footer-brand w-nav-brand">
                  <img
                    src="../assets/logo2.png"
                    loading="lazy"
                    height="30"
                    width="130px"
                    alt="TIERS Limited"
                    className="logo-footer-image"
                  />
                </a>
                <p
                  className="footer-brand-description mt-3"
                  style={{ fontSize: "14px" }}
                >
                  Greater Boston Area, <br />
                  Boston, MA, <br />
                  United States
                </p>
                <a
                  href="mailto:sales@tiers.com?subject=Website%20Interest"
                  className="footer-email-link"
                  style={{ fontSize: "14px" }}
                >
                  info@tierslimited.com
                </a>
              </div>
            </div>

            <div className="col-md-3 mb-4">
              <h5
                className="footer-links-header"
                style={{ fontSize: "16px", marginBottom: "10px" }}
              >
                Compnay
              </h5>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                  to="/about"
                  className="footer-link "
                  
                >
                  About
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                  to="/services"
                  className="footer-link "
                  
                >
                  Services
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                  to="/products"
                  className="footer-link "
                  
                >
                  Products
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                  to="/portfolio"
                  className="footer-link "
                  
                >
                  Portfolio
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                  to="/career"
                  className="footer-link "
                  
                >
                  Careers
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                  to="/gallery"
                  className="footer-link "
                  
                >
                  Gallery
                </Link>
              </p>
            </div>

            <div className="col-md-3 mb-4">
              <h5 className="footer-links-header" style={{ fontSize: "16px" }}>
                Services
              </h5>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Web Development', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Web Development
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Mobile App Development', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Mobile App Development
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Generative AI', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Generative AI
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Custom Development', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Custom Development
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Digital Marketing', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Digital Marketing
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Product Development', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Product Development
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'Graphic Design', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  Graphic Design
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'DevOps', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  DevOps
                </Link>
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                <Link
                to='services'
                  className="footer-link "
                  onClick={() => {
                    Cookies.set('service', 'UI/UX', { expires: 1 });
                    // window.location.href = '/services';
                  }}
                >
                  UI/UX
                </Link>
              </p>
            </div>

            <div className="col-md-3">
              <h5 className="footer-links-header" style={{ fontSize: "16px" }}>
                Newsletter
              </h5>
              <p
                style={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "normal",
                }}
              >
                Stay updated with the latest in tech.
              </p>
              <form onSubmit={handleSubmit} className="subscribe-form d-flex flex-column">
      <div className="input-group mb-2">
        <span
          className="input-group-text"
          id="email-addon"
          style={{
            backgroundColor: "transparent",
            border: "none",
            borderBottom: "1px solid white",
            borderRadius: "0",
          }}
        >
          <FaEnvelope style={{ color: "white" }} />
        </span>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          aria-label="Email"
          aria-describedby="email-addon"
          required
          style={{
            backgroundColor: "transparent",
            color: "white",
            border: "none",
            borderBottom: "1px solid white",
            borderRadius: "0",
            // Ensure placeholder color is white
            '::placeholder': {
              color: 'white',
            },
          }}
        />
        <button
          type="submit"
          className="btn btn-outline-light"
          style={{
            backgroundColor: "transparent",
            border: "none",
            borderBottom: "1px solid white",
            borderRadius: "0",
          }}
        >
          <FaPaperPlane />
        </button>
      </div>

      <style jsx>{`
        input::placeholder {
          color: white !important;
        }
      `}</style>
    </form>

              <div className="footer-social-icons-wrapper mt-4">
                <a
                  href="https://www.facebook.com/profile.php?id=100092471007661"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-icon"
                >
                  <FaFacebook size={24} />
                </a>
                <a
                  href="https://www.instagram.com/tierslimited/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-icon ms-3"
                >
                  <FaInstagram size={24} />
                </a>
                <a
                  href="https://www.linkedin.com/company/tiers-limited/mycompany/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-icon ms-3"
                >
                  <FaLinkedin size={24} />
                </a>
                {/* <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-social-icon ms-3"
                >
                  <FaTwitter size={24} />
                </a> */}
                {/* <a href="https://www.youtube.com/channel/UCxxxxxxxxxxxxxxxx" target="_blank" rel="noopener noreferrer" className="footer-social-icon ms-3">
                  <FaYoutube size={24} />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="text-center py-3"
        style={{ backgroundColor: "#003467", color: "white", padding: "0px" }}
      >
        <div className="container" style={{ borderTop: "1px solid #ccc" }}>
          <div
            className="row"
            style={{ backgroundColor: "#003467", paddingTop: "5px" }}
          >
            <div className="col">
              <a
                href="#"
                className="footer-link bottom me-3"
                style={{ fontSize: "14px", marginTop: "30px" }}
              >
                © 2021 Copyright: TiersLimited.com
              </a>
              {/* <a href="./privacy-policy/index.html" className="footer-link bottom">Privacy Policy</a> */}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .footer-link {
          color: white;
          text-decoration: none;
          font-weight: normal;
          font-size: 14px
        }

        .footer-link:hover {
        color: white;
          text-decoration:underline;
        }

        .footer-social-icon {
          color: white;
          transition: color 0.3s;
        }

        .footer-social-icon:hover {
          color: #0398f8;
        }
      `}</style>
    </>
  );
}
