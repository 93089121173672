import React from 'react';

// CareerItem component to represent each career opportunity
const CareerItem = ({ job }) => {
  return (
    <div className="career-item" style={{
      display: 'flex',
      padding: '20px',
      backgroundColor: '#f7f7f7',
      borderRadius: '10px',
      marginBottom: '20px',
      alignItems: 'center',
    }}>
      <div className="career-image-col" style={{ flex: '0 0 100px', marginRight: '20px' }}>
        <img
          src={job.image}
          alt={job.role}
          style={{ width: '100px',height:'70px', borderRadius: '10px' }}
        />
      </div>
      <div className="career-info-col" style={{ flex: '1', color: '#000' }}>
        <div className="career-role" style={{
          fontWeight: 'bold',
          fontSize: '20px',
          marginBottom: '10px',
          color: '#003467',
        }}>
          {job.role}
        </div>
        <div className="career-details" style={{ marginBottom: '10px', color: '#000' }}>
          <span>{job.timePosted} • </span>
          <span>{job.type} • </span>
          <span>{job.location}</span>
        </div>
      </div>
      <div className="career-skills-col" style={{ flex: '1', color: '#333' }}>
        <div className="career-skills" style={{ color: '#0056b3', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          {job.skills.map((skill, index) => (
            <span key={index} style={{
              backgroundColor: '#e0e7ff',
              padding: '5px 10px',
              borderRadius: '15px',
              fontSize: '14px',
              color: '#003467',
            }}>
              {skill}
            </span>
          ))}
        </div>
      </div>
      <style>
        {`
        .career-item {
  display: flex;
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 10px;
  margin-bottom: 20px;
  align-items: center;
}

.career-image-col {
  flex: 0 0 150px;
  margin-right: 20px;
}

.career-info-col {
  flex: 1;
  color: #333;
}

.career-role {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  color: #003467;
}

.career-details {
  margin-bottom: 10px;
  color: #777;
}

.career-skills-col {
  flex: 1;
  color: #333;
}

.career-skills {
  color: #0056b3;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.career-skills span {
  background-color: #e0e7ff;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  color: #003467;
}

        `}
      </style>
    </div>
  );
};

const HomeCtaSection = () => {
    const careers = [
        {
          image: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGZ1bGwlMjBzdGFjayUyMGRldmVsb3BlcnxlbnwwfHwwfHx8MA%3D%3D',
          role: 'Full Stack Developer',
          timePosted: '1 day ago',
          type: 'Full-time',
          location: 'Remote',
          skills: ['HTML', 'CSS', 'React', 'Node.js']
        },
        {
          image: 'https://images.unsplash.com/photo-1667372393086-9d4001d51cf1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YmFja2VuZCUyMGRldmVsb3BlcnxlbnwwfHwwfHx8MA%3D%3D',
          role: 'Backend Developer',
          timePosted: '2 days ago',
          type: 'Part-time',
          location: 'Onsite',
          skills: ['Node.js', 'Express', 'MongoDB']
        },
        {
          image: 'https://images.unsplash.com/photo-1667372531881-6f975b1c86db?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8ZnJvbnRlbmQlMjBkZXZlbG9wZXJ8ZW58MHx8MHx8fDA%3D',
          role: 'Frontend Developer',
          timePosted: '3 days ago',
          type: 'Contract',
          location: 'Remote',
          skills: ['JavaScript', 'React', 'Vue.js', 'CSS']
        },
        // {
        //   image: 'https://images.unsplash.com/photo-1563449608-8d8f4dd0e2e0?w=500&auto=format&fit=crop&q=60',
        //   role: 'UX/UI Designer',
        //   timePosted: '5 days ago',
        //   type: 'Full-time',
        //   location: 'Hybrid',
        //   skills: ['Adobe XD', 'Sketch', 'Figma', 'User Research']
        // },
        // {
        //   image: 'https://images.unsplash.com/photo-1581093807030-6d6f6bdfb997?w=500&auto=format&fit=crop&q=60',
        //   role: 'Data Scientist',
        //   timePosted: '1 week ago',
        //   type: 'Full-time',
        //   location: 'Remote',
        //   skills: ['Python', 'Machine Learning', 'SQL', 'Data Visualization']
        // },
        // {
        //   image: 'https://images.unsplash.com/photo-1563283064-df3d02066368?w=500&auto=format&fit=crop&q=60',
        //   role: 'DevOps Engineer',
        //   timePosted: '2 weeks ago',
        //   type: 'Part-time',
        //   location: 'Onsite',
        //   skills: ['Docker', 'Kubernetes', 'CI/CD', 'AWS']
        // }
        // Add more career data as needed
      ];
      

  return (
    <section className="section home-4-cta" style={{background: 'linear-gradient(to top, #8fd1fc, #fff)'}}>
      <div className="w-layout-blockcontainer base-container w-container">
        <div className="cta-block-gradient"></div>
        <div className="text-wrap-center-block-cta">
          <div className="cta-banner-heading" style={{color:'#003467'}}>
            Learn more about our current openings.
          </div>
        </div>
        <a
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          className="button-primary-with-arrow cta w-inline-block"
          style={{ textDecoration: 'none' }}
        >
          <div>Career Opportunities</div>
        </a>
        <div className="careers-section" style={{ margin: '50px 0px', maxHeight:'500px', overflowY:'auto' ,zIndex:'999999999'}}>
          {careers.map((job, index) => (
            <CareerItem key={index} job={job} />
          ))}
        </div>
      </div>
      {/* <img
        src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a2711d_Logo%20Home%203.svg"
        loading="lazy"
        alt=""
        className="cta-logo-bg left"
      />
      <img
        src="https://cdn.prod.website-files.com/65e9d802e7334ec910a26e59/65e9d803e7334ec910a26fab_cta%20bg.svg"
        loading="lazy"
        alt=""
        className="cta-logo-bg right"
      /> */}
    </section>
  );
};

export default HomeCtaSection;
